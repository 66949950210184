import * as lan from './common_lan.js';
import * as enc from '../EncryptPack/AES256/aes256.js';


import axios from 'axios';
import stringify from 'node-stringify';
import { Link, useLocation, useNavigate, useNavigation } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify';

export const commonKeyDic = {};
commonKeyDic['Language'] = {0 : 'ko', 1 : 'en', 2 : 'zh', 3 : 'vi', 4: 'ja'};
commonKeyDic['ko'] = {imageurl : 'images/flag/square_kr.png', name : 'Korean', country : 'South Korea', term : 'Terms_KR', countryValue : 'KR'};
commonKeyDic['en'] = {imageurl : 'images/flag/square_us.png', name : 'English', country : 'United States', term : 'Terms_US', countryValue : 'US'};
commonKeyDic['zh'] = {imageurl : 'images/flag/square_cn.png', name : 'Chinese', country : 'China', term : 'Terms_CN', countryValue : 'CN'};
commonKeyDic['vi'] = {imageurl : 'images/flag/square_vn.png', name : 'Vietnam', country : 'Vietnamese', term : 'Terms_VN', countryValue : 'VN'};
commonKeyDic['ja'] = {imageurl : 'images/flag/square_jp.png', name : 'Japanese', country : 'Japan', term : 'Terms_JP', countryValue : 'JP'};
commonKeyDic['difficulty'] = {1 : 'Beginner', 2 : 'Amateur', 3 : 'SemiPro', 4 : 'Pro', 5 : 'TourPro'};
commonKeyDic['teeBoxType'] = {1 : 'Red', 2 : 'Yellow', 3 : 'White', 4 : 'Blue', 5 : 'Black', 6: 'Junior'};
commonKeyDic['teeHeight'] = {0 : 10, 1 : 15, 2 : 20, 3 : 25, 4 : 30, 5 : 35, 6 : 40, 7 : 45, 8 : 50, 9 : 55, 10 : 60};

commonKeyDic['clientType'] = {All : '전체', screen : 'screen', practice : 'practice', lesson : 'lesson'};
commonKeyDic['gameType'] = {All : '전체', stroke : '스트로크', foursome : '포섬', putting : '퍼팅게임' , shotgame : '샷게임'};
commonKeyDic['gameTypeVal'] = {All : '', stroke : 1, foursome : 2, putting : 3 , shotgame : 4};
commonKeyDic['practiceType'] = {1 : '드라이빙레인지', 2 : '어프로치', 3 : '직선 퍼팅', 4 : '곡선 퍼팅' , 5 : '숏게임'};
commonKeyDic['distance'] = {0 : 5, 1 : 10, 2 : 20, 3 : 30};
commonKeyDic['destinationLandType'] = {0: 'Fairway', 1: 'Green', 2: 'Land', 3: 'Rough', 4: 'Hazard', 5: 'Bunker', 6: 'Apron', 7: 'Road', 8: 'Green Bunker',9: 'Grass Bunker', 10: 'Middle Rough', 11: 'Heavy Rough', 
																	12: 'Teeing Ground', 13: 'Water Hazard', 14: 'Road Ob', 15: 'Road Hazard', 16: 'Road Water Hazard', 17: 'Second Green', 18: 'Unused Green'};
commonKeyDic['ballFlightType'] = { 0: 'Unknown', 1: 'Straight', 2: 'Push', 3: 'Push Hook', 4: 'Push Slice', 5: 'Push Draw', 6: 'Push Fade', 7: 'Pull', 8: 'Pull Hook', 9: 'Pull Slice', 10: 'Pull Draw', 11: 'Pull Fade'
															, 12: 'Hook', 13: 'Slice', 14: 'Draw', 15: 'Fade'}
commonKeyDic['recommandClub'] = { 0: 'Driver', 1: '2Wood ', 2: '3Wood', 3: '4Wood', 4: '5Wood', 5: '6Wood', 6: '7Wood', 7: '8Wood', 8: '9Wood', 9: '2Utill', 10: '3Utill ', 11: '4Utill', 12: '5Utill',
13: '6Utill', 14: '7Utill', 15: '8Utill', 16: '9Utill', 17: '2Iron', 18: '3Iron', 19: '4Iron', 20: '5Iron', 21: '6Iron', 22: '7Iron', 23: '8Iron', 24: '9Iron', 25: 'PW', 26: 'GW',
27: 'AW', 28: 'SW', 29: '56W', 30: '58W', 31: '60W', 32: '62W', 33: '64W', 34: '66W', 35: 'Putter'}
// commonKeyDic['club'] = { All : '전체' , Driver : 'Driver' , Wood3 : '3Wood' , Wood5 : '5Wood' , Utill3 : '3Utill' , Utill5 : '5Utill' , Iron3 : '3Iron' , Iron4 : '4Iron' , Iron5 : '5Iron' , Iron6 : '6Iron' , Iron7 : '7Iron' 
// 											, PW : 'PW' , AW : 'AW' , SW : 'SW' , Putter : 'Putter' }
// commonKeyDic['clubVal'] = { All : '' , Driver : 0 , Wood3 : 2 , Wood5 : 4 , Utill3 : 10 , Utill5 : 12 , Iron3 : 18 , Iron4 : 19 , Iron5 : 20 , Iron6 : 21 , Iron7 : 22 
// 											, PW : 25 , AW : 27 , SW : 28 , Putter : 35 }
commonKeyDic['club'] = { All : '전체' , Driver: 'Driver', Wood2: '2Wood ', Wood3: '3Wood', Wood4: '4Wood', Wood5: '5Wood', Wood6: '6Wood', Wood7: '7Wood', Wood8: '8Wood', Wood9: '9Wood', Utill2: '2Utill', Utill3: '3Utill ', Utill4: '4Utill', Utill5: '5Utill',
											Util6l: '6Utill', Utill7: '7Utill', Utill8: '8Utill', Utill9: '9Utill', Iron2: '2Iron', Iron3: '3Iron', Iron4: '4Iron', Iron5: '5Iron', Iron6: '6Iron', Iron7: '7Iron', Iron8: '8Iron', Iron9: '9Iron', PW: 'PW', GW: 'GW',
											AW: 'AW', SW: 'SW', W56: '56W', W58: '58W', W60: '60W', W62: '62W', W64: '64W', W66: '66W', Putter: 'Putter'}
commonKeyDic['clubVal'] = { All : '' , Driver: 0, Wood2: 1 , Wood3: 2, Wood4: 3, Wood5: 4, Wood6: 5, Wood7: 6, Wood8: 7, Wood9: 8, Utill2: 9, Utill3: 10 , Utill4: 11, Utill5: 12,
											Util6l: 13, Utill7: 14, Utill8: 15, Utill9: 16, Iron2: 17, Iron3: 18, Iron4: 19, Iron5: 20, Iron6: 21, Iron7: 22, Iron8: 23, Iron9: 24, PW: 25, GW: 26,
											AW: 27, SW: 28, W56: 29, W58: 30, W60: 31, W62: 32, W64: 33, W66: 34, Putter: 35}
commonKeyDic['coursesize'] = { full : 18 , half : 9 }
commonKeyDic['defaultaddr'] = { lati : 37.516348191898 , long : 127.02937346935 }

commonKeyDic['roundshotGrade'] = {0 : 'Normal', 1 : 'Good' , 2 : 'Ceremony' , 3 : 'HoleIn' , 4 : 'Normal', 5 : 'Good', 6 : 'Ceremony', 7 : 'HoleIn' , 8 : 'Normal', 9 : 'Good', 10 : 'Ceremony', 11	 : 'HoleIn'};
commonKeyDic['shotGrade'] = {0 : 'Normal', 1 : 'Good' , 2 : 'Ceremony' , 3 : 'Perfect' , 4 : 'OhkShot', 5 : 'HoleIn'};

commonKeyDic['approachDistance'] = { 0 : 25 , 1 : 30 , 2 : 40 , 3 : 50 , 4 : 60 , 5 : 70 , 6 : 80 , 7 : 90 , 8 : 100 , 9 : 110 , 10 : 120 , 11 : 130, 12 : 140, 13 : 150, 14 : 160, 15 : 170, 16 : 180, 17 : 190, 18 : 200 }
commonKeyDic['straightPuttingDistance'] = { 0 : 1 , 1 : 2 , 2 : 3 , 3 : 4 , 4 : 5 , 5 : 7 , 6 : 10 , 7 : 15 , 8 : 20 , 9 : 25}

commonKeyDic['average'] = {0 : 5, 1 : 10, 2 : 15, 3 : 30};
commonKeyDic['match'] = {all : '전체', shop : '매장 대회', global : '글로벌 대회'};

commonKeyDic['appVersion'] = {android : '1.1.0', Ios : '1.0.4'}; // 배포버전과 동일하게 작성!!
commonKeyDic['ForceUpdate'] = {android : 'Y', Ios : 'Y'};

commonKeyDic['swingPlate'] = {0 : '고정', 1 : '아마추어' , 2 : '프로'};
commonKeyDic['windSpeed'] = {0 : '없음', 1 : '약함' , 2 : '보통' , 3 : '강함', 4 : '매우 강함'};
commonKeyDic['greenLocation'] = {0 : '랜덤', 1 : '왼쪽' , 2 : '오른쪽'};
commonKeyDic['holecupLocation'] = {0 : '랜덤', 1 : '앞' , 2 : '중간' , 3 : '뒤'};
commonKeyDic['greenSpeed'] = {0 : '매우느림', 1 : '느림' , 2 : '보통' , 3 : '약간빠름' , 4 : '매우빠름'}; //API 상에서는 -2 ~ 2 이기때문에 +2를 해야함
commonKeyDic['gender'] = {0 : '남성', 1 : '여성'};
commonKeyDic['recordType'] = {0 : 'Stroke', 1 : 'NewPerio', 2 : 'BirdieCount', 3 : 'EagleCount' , 4 : 'Longest' , 5 : 'Nearpin'};
commonKeyDic['participationGrade'] = {2 : '브론즈', 3 : '실버' , 4 : '골드' , 5 : '플래티넘', 6 : '다이아몬드'};

commonKeyDic['dayofweek'] = {0 : '일', 1 : '월', 2 : '화', 3 : '수' , 4 : '목' , 5 : '금' , 6 : '토'};
commonKeyDic['testIP'] = {pointPC : '116.120.116.98', pointMobile : '118.223.113.155', GyoPC : '211.36.149.127', kbWifi : '112.169.84.252', hotspot : '223.38.45.82'}; 

export const commonPhoneDic = [{settingName : 'Australia' , settingValue : 'AU', phoneValue : '61'},
															{settingName : 'Cambodia' , settingValue : 'KH', phoneValue : '855'},
															{settingName : 'Canada' , settingValue : 'CA', phoneValue : '1'}, 
															{settingName : 'China' , settingValue : 'CN', phoneValue : '86'}, 
															{settingName : 'India' , settingValue : 'IN', phoneValue : '91'}, 
															{settingName : 'Indonesia' , settingValue : 'ID', phoneValue : '62'},
															{settingName : 'Japan' , settingValue : 'JP', phoneValue : '81'}, 
															{settingName : 'Malaysia' , settingValue : 'MY', phoneValue : '60'}, 
															{settingName : 'New Zealand' , settingValue : 'NZ', phoneValue : '64'}, 
															{settingName : 'Philippines' , settingValue : 'PH', phoneValue : '63'}, 
															{settingName : 'Singapore' , settingValue : 'SG', phoneValue : '65'},
															{settingName : 'South Korea' , settingValue : 'KR', phoneValue : '82'},
															{settingName : 'Taiwan' , settingValue : 'TW', phoneValue : '886'}, 
															{settingName : 'Thailand' , settingValue : 'TH', phoneValue : '66'},
															{settingName : 'United States' , settingValue : 'US', phoneValue : '1'}, 
															{settingName : 'Viet Nam' , settingValue : 'VN', phoneValue : '84'}
														];

const commonErrorDic = {};
commonErrorDic[1000] = '지정되지 않은 오류가 발생했습니다.';
commonErrorDic[1001] = '모델 바인딩 중 오류발생';
commonErrorDic[3183] = '매장 즐겨찾기는 1개만 할 수 있습니다.';
commonErrorDic[4112] = '존재하지 않는 아이디입니다.';
commonErrorDic[1052] = 'Access Token의 claims값이 잘못되었습니다.';
commonErrorDic[4000] = '회원정보를 확인 할 수 없습니다.';
commonErrorDic[4002] = '조회되는 회원이 없습니다.';
commonErrorDic[4060] = '비밀번호가 일치하지 않습니다.';
commonErrorDic[4061] = '현재 비밀번호와 새로운 비밀번호가 일치합니다.';
commonErrorDic[4088] = '이미 사용중인 닉네임입니다.';
commonErrorDic[4109] = '회원정보를 확인 할 수 없습니다.';
commonErrorDic[4046] = '이미 사용중인 아이디입니다.';
commonErrorDic[4055] = '이미 동일한 값을 가지고 있습니다.';
commonErrorDic[4113] = '비밀번호가 일치하지 않습니다.';
commonErrorDic[4065] = '이미 동일한 값을 가지고 있습니다.';
commonErrorDic[4089] = '휴대폰 번호 유효성 검사 실패';
commonErrorDic[4091] = '이미 존재하는 휴대폰 번호입니다.';
commonErrorDic[7743] = '본인 회원 정보만 수정가능합니다 .';
commonErrorDic[7759] = '보관할 수 있는 스윙 영상의 최대 개수를 초과하였습니다.';
commonErrorDic[7768] = '보관할 수 있는 영상의 최대 개수를 초과하였습니다.';
var navigate;

export function showAlert(message) {
	toast.info(message);
}

export function getCommonKeyString(num, key)
{
	return commonKeyDic[key][num];
}

export function getCommonKeyByValue(value, keycode)
{
	  return Object.keys(commonKeyDic[keycode]).find(key => commonKeyDic[keycode][key] === value);
}

export function getPhoneValueBySettingValue(value)
{
	for(let i =0; i < commonPhoneDic.length; i++)
	{
		if(value == commonPhoneDic[i].settingValue)
		{
			return commonPhoneDic[i].phoneValue;
		}
	}
}

export function Length(value, example) //example은 0 또는 0 m 등
{
	const distanceUnit = sessionStorage.getItem('distanceUnit')
	var returnval = example;
	if (value !== undefined)
	{
		if(distanceUnit == 'yd')
		{
			value = (value * 1.09361).toFixed(2);
			returnval = example.replace('meter','yard');
			returnval = returnval.replace('m',distanceUnit);
		}
		returnval = returnval.replace('0',value);
		return returnval;
	}
	return '';
}

export function Speed(value, example) //example은 0 또는 0 m 등
{
	const distanceUnit = sessionStorage.getItem('distanceUnit')
	var returnval = example;
	if (value !== undefined)
	{
		if(distanceUnit == 'yd')
		{
			value = (value * 2.23694).toFixed(2);
			returnval = example.replace('m/s','mph');
			returnval = returnval.replace('m/s','mph');
		}
		returnval = returnval.replace('0',value);
		return returnval;
	}
	return '';
}

export function vaildChkData(pType, pStrVal) {
	var ptnDay = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;	//날짜형식 ex)2020-01-22
	var ptnOnlyNum = /^[\d]+$/;	//숫자만
	var ptnOnlyNumpw = /^[\d]{6,6}$/;	//숫자만
	var ptnUserID = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9+]{6,20}$/;  //아이디 6~20자리 영문, 숫자
	//var ptnPwChk1 = /^[a-zA-Z0-9]{8,16}$/; //영어 or 숫자 8~16자로	
	//var ptnPwChk1=/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9+]{8,16}$/;  //비밀번호 : 영문+숫자 8~16자리  영어,숫자 필수, 특수문자 안됨
	var ptnPwChk1=/^(?=.*[a-zA-Z])(?=.*[!@#$%^*\-_+])(?=.*[0-9]).{6,20}$/i; //특정특수문자포함 + 영문+숫자
	///^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9+]{6,16}$/;  //비밀번호 : 영문+숫자 6~16자리  영어,숫자 필수, 특수문자 안됨
	///^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?])*.{4,20}$/i;  //문자+숫자(+특수문자) :  특수문자도 필수로 하려면 뒤에 *를 빼면됨

	var ptnPwChk2 = /[a-z]/i;  //적어도 한개의 a-z 확인
	var pthPwChk3 = /\d/;  //적어도 한개의 0-9 확인
	var ptnHp = /^0(?:10|11|16|17|18|19)-(?:\d{3}|\d{4})-\d{4}$/;
	var ptnTel = /^\d{2,3}-\d{3,4}-\d{4}$/;
	var ptnEmail =  /^[-!#$%&amp;'*+./0-9=?A-Z^_a-z{|}~]*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
	//var ptnEmail=  /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
	//var ptnPw2 = /^[a-z]{1}(?=.*\d)[a-z\d]{5,11}$/; //영소문자+숫자 6~12자 이내
	var space = /\S/;	//공백
	var ptnkorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;		
	//var ptnName = /^[가-힣|a-z|A-Z]+$/; 
	var ptnName = /^[가-힣|a-z|A-Z|0-9]{4,20}$/; //이름 : 영어,한글 4~20자 이내
	var ptnID =  /^[a-zA-Z0-9]{4,20}$/; //영어 or 숫자 4~20자로	
	var ptnkorean2 = /^[가-힣]{2,20}$/; //이름 : 한글만 2~20자 이내
	var ptnName3 = /^[가-힣\s|a-z\s|A-Z\s]{2,30}$/; //이름 : 영어,한글, 공백 2~30자 이내
	//var ptnkorean2 = /^[가-힣]+$/;

	var retvalid = false;
	switch (pType) {				
		case 'id':if(!(ptnID.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'pw':if(!(ptnOnlyNumpw.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'nickname':if(!(ptnName.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'name3':if(!(ptnName3.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'korean':if(!(ptnkorean.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'date' : if(!(ptnDay.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'number' : if(!(ptnOnlyNum.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'password' :
			if(!(ptnPwChk1.test(pStrVal))){ retvalid = false;} else {retvalid = true;} //8~16영어,숫자 필수, 특수문자 안됨 확인							
			/* 반복문자 3개이상 못오게*/
			if(retvalid){
				if (pStrVal.match(/(\w)\1\1/)) {						
					retvalid=false;
				}
				/*
				for(var i=pStrVal.length-1;i>=2;i--)	{
					if((pStrVal.charAt(i)==pStrVal.charAt(i-1)) && (pStrVal.charAt(i)==pStrVal.charAt(i-2)))
					{
						retvalid=false;
						break;
					}
				}
				*/ 					
			}
			
			//if(!(ptnPwChk1.test(pStrVal) && ptnPwChk2.test(pStrVal) && pthPwChk3.test(pStrVal))){retvalid = false;	}else {retvalid = true;} 			
			break;
		case 'hp' : if(!(ptnHp.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'tel' : if(!(ptnTel.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'userid':if(!(ptnUserID.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;
		case 'email':if(!(ptnEmail.test(pStrVal))){ retvalid = false;} else {retvalid = true;} break;		
	}
	return retvalid;
}

export function alertByError(title,errorcode)
{
	if(commonErrorDic[errorcode] !== undefined && commonErrorDic[errorcode] !== null)
	{
		alertpop(lan.L(title),lan.L(commonErrorDic[errorcode]));
	}
	else
	{
		alertpop(lan.L(title),lan.L("에러 발생")+" ("+lan.L("에러코드")+" : " + errorcode + ")");
	}
}

export function setNowLan(value)
{
	sessionStorage.setItem('Language',value)

	if(isIOS()){
		iOSCallApp({appid:"setLanguage", params: value});
	}
	else
	{
		try{andriodCallApp('setLanguage',[value]);}
		catch (ex){}
	}
}
	
export function UnixTime(value, example)
{
	var time =  Math.floor(value / 1000);

	var date = new Date(time*1000);
	var year = date.getFullYear();
	var month = "0" + (date.getMonth()+1);
	var day = "0" + date.getDate();
	var hour = "0" + date.getHours();
	var minute = "0" + date.getMinutes();
	var second = "0" + date.getSeconds();
	var dayofweek = date.getDay();

	var daydiff = Math.floor(time / 86400);

	var returnvalue = example.replace("날짜",date);
	returnvalue = returnvalue.replace("일자",daydiff);
	returnvalue = returnvalue.replace("년",year);
	returnvalue = returnvalue.replace("월",month.substr(-2));
	returnvalue = returnvalue.replace("일",day.substr(-2));
	returnvalue = returnvalue.replace("시",hour.substr(-2));
	returnvalue = returnvalue.replace("분",minute.substr(-2));
	returnvalue = returnvalue.replace("초",second.substr(-2));
	returnvalue = returnvalue.replace("요일",lan.L(Object.values(commonKeyDic['dayofweek'])[dayofweek]) );

	return returnvalue;
}

export function getStringToDate(value, marker){
	if(value == '' || value === undefined || value == null) return ''
	if(value.indexOf(marker) < 0)
	{
		return value.substr(0,4) + marker + value.substr(4,2) + marker + value.substr(6,2);
	}
	else
	{
		return value
	}
}

export function getDistance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2))
        return 0;

    var radLat1 = Math.PI * lat1 / 180;
    var radLat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radTheta = Math.PI * theta / 180;
    var dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    if (dist > 1)
        dist = 1;

    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344 * 1000;
    if (dist < 100) dist = Math.round(dist / 10) * 10;
    else dist = Math.round(dist / 100) * 100;

    return dist / 1000;
}

export function getSequenceFormat(value) {
    if(value == 1) return '1st'
	else if(value == 2) return '2nd'
	else return value+'th'
}

export function getSequenceFormatText(value) {
    if(value == 1) return 'st'
	else if(value == 2) return 'nd'
	else return 'th'
}

//해당 이하 함수만 안드, 아이폰에서 반응함 (앱 호출함수들)
window.NativeInterface = {
	//QR코드 체크 후 케이골프 API에서 케이골프 키오스크 로그인처리
	goQRLogin: (tokentxt) => {
		console.log(tokentxt);
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {
				token : tokentxt
			},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('QR코드 로그인'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			console.log(stringify(response.data));
			var data = response.data;

			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				alertByError('',response.data.error.code);
				return false;
			}
			else
			{
				alertpop(lan.L('QR로그인 완료'),lan.L('QR 로그인되었습니다.\n게임을 시작하세요.'));
			}
		}).catch(error => {
			//console.log(error.response)
			return false;
		});
	},
	//QR코드 체크 후 실패시 실행
	goQRFail: (failcode) => {
		console.log('failcode : ' + failcode);
		alertpop(lan.L('QR로그인 실패'),lan.L('로그인정보를 확인 할 수 없습니다. \n다시 확인해주세요.'));
	// your javascript code
	return false;
	},
	//앱실행시 앱에 지정되어있던 언어 받아서 세션에 저장 처리
	getLanguage: (language) => {
		sessionStorage.setItem('Language', 'en');
		//디폴트값 en

		for(let i = 0; i< Object.keys(commonKeyDic['Language']).length; i++)
		{
			if(commonKeyDic['Language'][i]==language)
			{
				sessionStorage.setItem('Language', language);
			}
		}
		$("#popupAlertBtn").html(lan.L('확인'));
		$("#popChoiceBtn").html(lan.L('확인'));
		$("#popChoiceBtnCancel").html(lan.L('취소'));
	},
	//앱실행시 실행
	getIntro: (introtext) => {
		window.localStorage.setItem('getIntro',"Y");

		if(introtext !== undefined && introtext != null && introtext != '')
		{
			var json = JSON.parse(introtext);
			var loginType;
			if(json.userName !== undefined && json.userName != null)
			{
				window.localStorage.setItem('intro_loginid',json.userName);
				window.localStorage.setItem('intro_loginpass',json.password.length > 6 ? enc.decrypt(json.password) : json.password);
				window.localStorage.setItem('intro_autologin',json.autoLoginYN);
				window.localStorage.setItem('intro_acesstoken',json.accessToken);
				window.localStorage.setItem('intro_loginType',json.loginType);
	
				if(window.localStorage.getItem('intro_autologin')=="Y")
				{
					sessionStorage.setItem('autoLogin', json.autoLoginYN);
					if(window.localStorage.getItem('intro_loginType') == "normal")
					{
						goLogin(navigate, null, window.localStorage.getItem('intro_loginid'), window.localStorage.getItem('intro_loginpass'), window.localStorage.getItem('intro_loginType'), window.localStorage.getItem('intro_autologin'));
					}
					else if(window.localStorage.getItem('intro_loginType') == "apple")
					{
						if(isIOS())
						{
							iOSCallApp({appid:"loginapple", params:"", funcname:"window.NativeInterface.loginapple"});
						}
						else
						{
							try{andriodCallApp('loginapple','');}
							catch (ex){}
						}
					}
					else if(window.localStorage.getItem('intro_loginType') == "kakao")
					{
						if(isIOS())
						{
							iOSCallApp({appid:"loginkakao", params:"", funcname:"window.NativeInterface.loginkakao"});
						}
						else
						{
							try{andriodCallApp('loginkakao','');}
							catch (ex){}
						}
					}
					else if(window.localStorage.getItem('intro_loginType') == "google")
					{
						if(isIOS())
						{
							iOSCallApp({appid:"logingoogle", params:"", funcname:"window.NativeInterface.logingoogle"});
						}
						else
						{
							try{andriodCallApp('logingoogle','');}
							catch (ex){}
						}
					}
					else if(window.localStorage.getItem('intro_loginType') == "facebook")
					{
						if(isIOS())
						{
							iOSCallApp({appid:"loginfacebook", params:"", funcname:"window.NativeInterface.loginfacebook"});
						}
						else
						{
							try{andriodCallApp('loginfacebook','');}
							catch (ex){}
						}
					}
				}
			}
			
			if(json.appVersion !== undefined && json.appVersion != null)
			{
				if(isIOS()){
					//ForceUpdate가 Y이고 Ios버전이 높으면 강제업데이트
					if(commonKeyDic['ForceUpdate'].Ios=="Y" && compareVersion(json.appVersion, commonKeyDic['appVersion'].Ios))
					{
						window.localStorage.setItem('popupOpenMarket','Y');
						window.localStorage.setItem('getIntro','N');
						alertpop(lan.L('업데이트'),lan.L("최신버전 앱으로 업데이트를 위해 스토어로 이동합니다."));
					}
				}
				else//안드로이드일때
				{
					//ForceUpdate가 Y이고 안드로이드 버전이 높으면 강제업데이트
					if(commonKeyDic['ForceUpdate'].android=="Y" && compareVersion(json.appVersion, commonKeyDic['appVersion'].android))
					{
						window.localStorage.setItem('popupOpenMarket','Y');
						window.localStorage.setItem('getIntro','N');
						alertpop(lan.L('업데이트'),lan.L("최신버전 앱으로 업데이트를 위해 스토어로 이동합니다."));
					}
				}
			}

			if(json.distanceUnit !== undefined && json.distanceUnit != null)
			{
				sessionStorage.setItem('distanceUnit',json.distanceUnit);
			}
		}
	},
	//앱 위치동의 받았는지 여부 호출. Y이면 지도값을 받지만 N이면 디폴트 케이골프 회사 위치를 가지고 있음
	getPermissionLocation: (YN) => {
		console.log('getPermissionLocation : ' + YN);
		if(YN=="N")
		{
			//alertpop(lan.L('권한 실패'),lan.L('권한에 실패했습니다.(문구변경예정)'));
		}
		else
		{
			navigator.geolocation.getCurrentPosition((position) => {
				window.localStorage.setItem('mylati',position.coords.latitude);
				window.localStorage.setItem('mylong',position.coords.longitude);
			});
		}
	},
	//카메라 값 호출(필요없어짐)
	getPermissionCamera: (YN) => {
		console.log('getPermissionCamera : ' + YN);
		if(YN=="N")
		{
			//alertpop(lan.L('권한 실패'),lan.L('권한에 실패했습니다.(문구변경예정)'));
		}
	},
	//앱최초실행했는지 여부
	getIsFirst: (YN) => {
		if(YN == "Y")
		{
			console.log("getIsFirst YN");
		 }
	},
	//앱최초실행했는지 여부
	goDownload: (url) => {
		var a = $("<a>")
			.attr("href", url)
			.attr("download", "")
			.appendTo("body")
			.attr("target","_blank");

		a[0].click();
		a.remove();
	},
	setPushToken: (token) => {	//푸시토큰 저장
	
		var device = isIOS() ? "ios" : "android"
		
		var data = {
			"deviceType": device,
			"pushNotificationToken": token
		};

		console.log(data);
	
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: data,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('회원 푸시알림 데이터 업데이트'),
				'guid' : sessionStorage.getItem('guid'),
				'token' : sessionStorage.getItem('access_token')
			}
		}).then(function (response) {
			console.log(response);
	
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				alertByError('로그인',response.data.error.code);
				return false;
			}

			window.localStorage.setItem('pushtoken',token);

		}).catch(error => {
			console.log(error.response)
		});
	},

	//SNS로그인
	loginkakao: (token) => {

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {"socialType" : "kakao", "idToken" : token, "authorizationCode" : ""},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('소셜 Id Token 검증 및 가입 확인'),
			}
		}).then(function (response) {
			console.log(response);
	
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				alertByError('로그인',response.data.error.code);
				return false;
			}
			
			window.localStorage.setItem('join_isJoin',response.data.isJoin);	//true false에 따라 login.js에서 페이지 이동처리
			window.localStorage.setItem('join_socialIdToken',token);
			window.localStorage.setItem('join_joinType','kakao');
			
			//로그인 시도 시 세션값
			window.localStorage.setItem('join_snsToken',response.data.snsToken);
			window.localStorage.setItem('join_loginToken',response.data.loginToken);

			//회원가입 페이지 이동 시 디폴트
			window.localStorage.setItem('join_agree01',false);
			window.localStorage.setItem('join_agree02',false);
			window.localStorage.setItem('join_agree03',false);
			window.localStorage.setItem('join_agree04',false);

			var ev = new Event('input', { bubbles: true});
			$("input[name='isJoinSNS']").val(true)[0].dispatchEvent(ev);
			
		}).catch(error => {
			console.log(error.response)
		});
	},
	logingoogle: (token) => {
		
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {"socialType" : "google", "idToken" : token, "authorizationCode" : ""},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('소셜 Id Token 검증 및 가입 확인'),
			}
		}).then(function (response) {
			console.log(response);
	
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				alertByError('로그인',response.data.error.code);
				return false;
			}
			
			window.localStorage.setItem('join_isJoin',response.data.isJoin);	//true false에 따라 login.js에서 페이지 이동처리
			window.localStorage.setItem('join_socialIdToken',token);
			window.localStorage.setItem('join_joinType','google');
			
			//로그인 시도 시 세션값
			window.localStorage.setItem('join_snsToken',response.data.snsToken);
			window.localStorage.setItem('join_loginToken',response.data.loginToken);

			//회원가입 페이지 이동 시 디폴트
			window.localStorage.setItem('join_agree01',false);
			window.localStorage.setItem('join_agree02',false);
			window.localStorage.setItem('join_agree03',false);
			window.localStorage.setItem('join_agree04',false);

			var ev = new Event('input', { bubbles: true});
			$("input[name='isJoinSNS']").val(true)[0].dispatchEvent(ev);
		}).catch(error => {
			console.log(error.response)
		});
	},
	loginfacebook: (token) => {
		
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {"accessToken" : token},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('페이스북 토큰 발행 및 가입 확인'),
			}
		}).then(function (response) {
			console.log(response);
	
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				alertByError('로그인',response.data.error.code);
				return false;
			}
			
			window.localStorage.setItem('join_isJoin',response.data.isJoin);	//true false에 따라 login.js에서 페이지 이동처리
			window.localStorage.setItem('join_socialIdToken',token);
			window.localStorage.setItem('join_joinType','facebook');

			//로그인 시도 시 세션값
			window.localStorage.setItem('join_snsToken',response.data.snsToken);
			window.localStorage.setItem('join_loginToken',response.data.loginToken);

			//회원가입 페이지 이동 시 디폴트
			window.localStorage.setItem('join_agree01',false);
			window.localStorage.setItem('join_agree02',false);
			window.localStorage.setItem('join_agree03',false);
			window.localStorage.setItem('join_agree04',false);

			var ev = new Event('input', { bubbles: true});
			$("input[name='isJoinSNS']").val(true)[0].dispatchEvent(ev);
		}).catch(error => {
			console.log(error.response)
		});
	},
	loginapple: (token, code) => {
		
		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: {"socialType" : "apple", "idToken" : token, "authorizationCode" : code},
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('소셜 Id Token 검증 및 가입 확인'),
			}
		}).then(function (response) {
			console.log(response);
	
			if(response.data.error !== undefined && response.data.error.code > 0)
			{
				alertByError('로그인',response.data.error.code);
				return false;
			}
			
			window.localStorage.setItem('join_isJoin',response.data.isJoin);	//true false에 따라 login.js에서 페이지 이동처리
			window.localStorage.setItem('join_socialIdToken',token);
			window.localStorage.setItem('join_joinType','apple');
			
			//로그인 시도 시 세션값
			window.localStorage.setItem('join_snsToken',response.data.snsToken);
			window.localStorage.setItem('join_loginToken',response.data.loginToken);

			//회원가입 페이지 이동 시 디폴트
			window.localStorage.setItem('join_agree01',false);
			window.localStorage.setItem('join_agree02',false);
			window.localStorage.setItem('join_agree03',false);
			window.localStorage.setItem('join_agree04',false);

			var ev = new Event('input', { bubbles: true});
			$("input[name='isJoinSNS']").val(true)[0].dispatchEvent(ev);

		}).catch(error => {
			console.log(error.response)
		});
	},
	//레슨북 갔다가 뒤로오면서 Count 실행
	getLessonbookCount: () => {

		//취소했다가 오기 때문에 setLessonbookUser를 작동
		if(isIOS()){
			iOSCallApp({appid:"setLessonbookUserYN", params: "Y"});
		}
		else
		{
			try{andriodCallApp('setLessonbookUserYN',["Y"]);}
			catch (ex){}
		}

		getLessonbookCount();
	},
	//앱실행시 앱에 지정되어있던 언어 받아서 세션에 저장 처리
	getLessonbookUserYN: (userYN) => {
		if(userYN == "Y")
		{
			getLessonbookCount();
		}
	},
}


export function cvtPhone(value, marker){
	var hpLen, result, tel1, tel2, tel3;

	if(value === undefined || value == null || $.trim(value) == ""){
	  result = ""
	}
	else if(value.indexOf(marker) > -1 )
	{
		result = value;
	}
	else{
		hpLen = value.length;

		if (value.substr(0,2) == "02"){
		  //일반전화 02인 경우
			if(hpLen<9 || hpLen > 10){
			  result= value;
			}else{
				tel1 = value.substr(0,2);
				tel3 = value.substr(-4);

				if (hpLen == 9){ tel2 = value.substr(2,3); }
				else { tel2 = value.substr(2,4);}

				result = tel1+marker+tel2+marker+tel3;
			}
		}else{
		  //휴대폰, 02제외 지역 일반전화
		  if(hpLen < 10 || hpLen > 11){
			  result = value;
		  }else{
			  tel1 = value.substr(0,3);
			  tel3 = value.substr(-4);

			  if (hpLen == 10){ tel2 = value.substr(3,3); }
			  else { tel2 = value.substr(3,4);}

			  result = tel1+marker+tel2+marker+tel3;
		  }
		}
	}
	return result;
}

export function alertpop(title,text) {

	$("#pop_alert_title").html(title);
	$("#pop_alert_text").html(text);

	if(title == '')
	{
		$("#pop_alert_text").addClass("alignCenter");
	}
	else
	{
		$("#pop_alert_text").removeClass("alignCenter");
	}

	$("html").addClass("overflowHidden");
	$("#pop_alert_layer").addClass("on");
}

export function alertchoice(title,text) {
	$("#pop_choice_title").html(title);
	$("#pop_choice_text").html(text);

	$("html").addClass("overflowHidden");
	$("#pop_choice_layer").addClass("on");
}

export function iOSCallApp(AppName){	//앱체크해서 앱인경우에만 처리 외부에서 필요
	console.log(AppName);
	window.webkit.messageHandlers.PBApp.postMessage(AppName);
}

export function andriodCallApp(AppName, params){	//앱체크해서 앱인경우에만 처리 외부에서 필요
	var list='';

	for(let i = 0; i<params.length;i++)
	{
		if(i==params.length-1)
		{
			list = list + '\"'+params[i]+'\"'
		}
		else
		{
			list = list + '\"'+params[i]+'\",'
		}
	}
	console.log( "window.PBApp." + AppName + "("+list+")" );

	eval ( "window.PBApp." + AppName + "("+list+")" );
}

export function isIOS(){
	var ua = window.navigator.userAgent.toLowerCase();	
	if(window.navigator.userAgent.toLowerCase().match('lessonbook_kgolf'))
	{
		 if ((ua.indexOf('iphone') != -1||ua.indexOf('macintosh') != -1||ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) && !window.MSStream) {
			return true;
		 }else{
			  return false;
		 }
	}
}

export function generateRandomCode(n) {
	let str = ''
	for (let i = 0; i < n; i++) {
	  str += Math.floor(Math.random() * 10)
	}
	return str
  }

	//앱버전비교. A가 크면 false B가 크면 true
  function compareVersion(verA, verB){

	var result = true;
	if(verA == verB)
	{
		return false;
	}
	verA = verA.split( '.' ); // .을 기준으로 문자열 배열로 만든다 [6][8]
	verB = verB.split( '.' ); // .을 기준으로 문자열 배열로 만든다 [6][7][99]

	const length = Math.max( verA.length, verB.length ); // 배열이 긴쪽의 length를 구함
	
	for ( var i = 0;  i < length ; i ++ ){
		var a = verA[i] ? parseInt(verA[i], 10 ) : 0; // 10진수의 int로 변환할 값이 없을 때 0으로 값을 넣습니다.
		var b = verB[i] ? parseInt(verB[i], 10 ) : 0;
		if ( a > b ) {
			return false;
		} else if (a < b) {
			return true;
		}
	}
	  return false;
}

  export function goLogin(navigate, navigatepath, loginID, loginPass, loginType, autologin) {

	console.log(process.env.REACT_APP_SERVER);
	
	//라이브 값
	//client_id:'kgolf_app',
	//client_secret:'t4wioeb4526EgfdasWGT4Euwi9pubg2w34ruo32jbdasQRE3WR4GT',

	//QA 아이디값
	//client_id:'kgolf_app_qa',
	//client_secret:'kgolf_QAApp123!',

	var client_id, client_secret;
	
	var host = window.location.host;
	if(host == 'qa-kgolf.g72.biz' || host == 'localhost:3000'|| host == 'dev-kgolf.g72.biz' || host == 'qa-app.thekgolf.com')
	{
		client_id = 'kgolf_app_qa';
		client_secret = 'kgolf_QAApp123!';
	}
	else
	{
		client_id = 'kgolf_app'
		client_secret = 't4wioeb4526EgfdasWGT4Euwi9pubg2w34ruo32jbdasQRE3WR4GT'
	}

	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {
			client_id: client_id,
			client_secret: client_secret,
			grant_type: 'password',
			scope: 'API_Auth User_Auth offline_access openid',
			username: loginID,
			password: loginPass,
			LoginPath: 'app',
			LoginType: loginType
		},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('로그인')
		}
	}).then(function (response) {
		var data = response.data;

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			alertByError('로그인',response.data.error.code);
			return false;
		}
		
		if(response.data.error_code !== undefined && response.data.error_code > 0)
		{
			alertByError('로그인',response.data.error_code);
			return false;
		}

		var accessToken = data.access_token

		if(isIOS())
		{
			iOSCallApp({appid:"setMemberLogin", params:loginID+'|@|'+enc.encrypt(loginPass)+'|@|'+data.access_token+'|@|'+autologin+'|@|'+loginType});
		}
		else
		{
			try{andriodCallApp('setMemberLogin',[loginID, enc.encrypt(loginPass), data.access_token, autologin, loginType]);}catch (ex){}
		}
		
		//console.log("status : " + response.status);
		sessionStorage.setItem('access_token', data.access_token);
		sessionStorage.setItem('expiresIn', data.expires_in);
		sessionStorage.setItem('token_type', data.token_type);
		sessionStorage.setItem('refresh_token', data.refresh_token);
		sessionStorage.setItem('scope', data.scope);
		sessionStorage.setItem('guid', data.guid);
		sessionStorage.setItem('nickname', data.nickname);
		sessionStorage.setItem('gender', data.gender);
		sessionStorage.setItem('cdn', data.cdn);
		
		sessionStorage.setItem('loginID', loginID);
		sessionStorage.setItem('showLoginID', data.id);
		sessionStorage.setItem('loginPass', loginPass);
		sessionStorage.setItem('autoLogin', autologin);
		sessionStorage.setItem('lessonbookCountCheck', "Y"); //navigation에서 Y일때만 count 호출
		
		if(isIOS())
		{
			iOSCallApp({appid:"setPushToken", params:'', funcname:"window.NativeInterface.setPushToken"});
		}
		else
		{
			try{andriodCallApp('setPushToken','');}catch (ex){
			}
		}

		if(navigatepath != null)
		{
			navigate(navigatepath, {replace:true});
		}
	}).catch(error => {
		//console.log(error.response)
		return false;
	});
}

export function goLogout() {

		//callapp 생성
		if(isIOS()){
			iOSCallApp({appid:"setLogout", params:''});
		}
		else
		{
			try{andriodCallApp('setLogout','');}catch (ex){}
		}
		
		sessionStorage.setItem('access_token', '');
		sessionStorage.setItem('expiresIn', '');
		sessionStorage.setItem('token_type', '');
		sessionStorage.setItem('refresh_token', '');
		sessionStorage.setItem('scope', '');
		sessionStorage.setItem('guid', '');
		sessionStorage.setItem('nickname', '');
		sessionStorage.setItem('gender', '');
		sessionStorage.setItem('cdn', '');

		sessionStorage.setItem('loginID', '');
		sessionStorage.setItem('loginPass', '');
		//sessionStorage.setItem('autoLogin', 'N');
}

export function setisTestIP() {
    axios.get('https://geolocation-db.com/json/')
    .then((res) => {
		var ip = res.data.IPv4;
		
		for(let i = 0; i < Object.keys(commonKeyDic['testIP']).length; i++)
		{
			if(Object.values(commonKeyDic['testIP'])[i] == ip)
			{
				sessionStorage.setItem('isTestIP', 'Y');
				return false;
			}
		}
		sessionStorage.setItem('isTestIP', 'N');
    })
}

const copyText = (text) => {
	const $textarea = document.createElement("textarea");

	// body 요소에 존재해야 복사가 진행됨
	document.body.appendChild($textarea);
	
	// 복사할 특정 텍스트를 임시의 textarea에 넣어주고 모두 셀렉션 상태
	$textarea.value = text;
	$textarea.select();
	
	// 복사 후 textarea 지우기
	document.execCommand('copy');
	document.body.removeChild($textarea);
}

export function goLessonbookReserve(centerCode, pageGbn) {

	//회원정보조회
	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {userGuid: sessionStorage.getItem('guid')},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 정보 조회'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		//console.log(response.data);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			alertByError('예약하기',response.data.error.code);
			return false;
		}

		var mobilePhone = response.data.mobilePhone;
		mobilePhone = cvtPhone(response.data.mobilePhone.replace("+"+getPhoneValueBySettingValue(response.data.mobilePhoneCountry),'0'),'')
		var pushToken = window.localStorage.getItem('pushtoken');
		var shopGbn = "kgolf";
		//centerCode = "10000017";

		// var urlLink = "https://lessonbook.g72.biz/app/bridge/default.asp?CenterIdx="+centerCode
		// 	+"&MobileNo="+mobilePhone+"&PushToken="+pushToken+"&ShopGbn="+shopGbn+"&PageGbn="+pageGbn; //변경 필요
		
		// var json = {
		// 	tokenType : "api",
		// 	tokenGbn : apiGbn,
		// 	partnerName : shopGbn,
		// 	centerIdx : "",
		// 	mobileNo : mobilePhone
		// }

		var json = {
			tokenType : "page",
			tokenGbn : pageGbn,
			partnerName : shopGbn,
			centerIdx : "",
			mobileNo : mobilePhone,
			pushToken : pushToken,
			centerIdx : centerCode
		}

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: json,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('레슨 토큰')
			}
		}).then(function (response) {
			// console.log(response);
			if(response.data.error !== undefined && response.data.error.errorCode != '0')
			{
				return false;
			}
			else
			{
				var urlLink = "https://lessonbook.g72.biz/app/bridge/default.asp?TokenID="+response.data.tokenId;
				var closeFuncYN = "Y";
				var closeFunction = "window.NativeInterface.getLessonbookCount()";
				var closeFunctionIOS = "window.NativeInterface.getLessonbookCount";
		
				if(isIOS()){
					iOSCallApp({appid:"crossDomain", params: urlLink +'|@|'+ closeFuncYN +'|@|'+ closeFunctionIOS});
				}
				else
				{
					try{andriodCallApp('crossDomain',[urlLink, closeFuncYN, closeFunction]);}catch (ex){
					}
				}
			}
			
		});

	}).catch(error => {
		return false;
	});
}

export function getLessonbookCount() {

	//회원정보조회
	axios({
		method: 'post',
		url: process.env.REACT_APP_SERVER + '/api',
		data: {userGuid: sessionStorage.getItem('guid')},
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'language':sessionStorage.getItem('Language'),
			'pUrl' : encodeURIComponent('회원 정보 조회'),
			'guid' : sessionStorage.getItem('guid'),
			'token' : sessionStorage.getItem('access_token')
		}
	}).then(function (response) {
		//console.log(response.data);

		if(response.data.error !== undefined && response.data.error.code > 0)
		{
			alertByError('예약하기',response.data.error.code);
			return false;
		}

		var mobilePhone = response.data.mobilePhone;
		mobilePhone = cvtPhone(response.data.mobilePhone.replace("+"+getPhoneValueBySettingValue(response.data.mobilePhoneCountry),'0'),'')
		var pushToken = window.localStorage.getItem('pushToken');
		var shopGbn = "kgolf";
		var apiGbn = "cnt";

		var urlLink = "https://lb-apigw.g72.biz/reserve/default.asp";
		
		var json = {
			tokenType : "api",
			tokenGbn : apiGbn,
			partnerName : shopGbn,
			centerIdx : "",
			mobileNo : mobilePhone
		}

		// console.log(json);

		axios({
			method: 'post',
			url: process.env.REACT_APP_SERVER + '/api',
			data: json,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'language':sessionStorage.getItem('Language'),
				'pUrl' : encodeURIComponent('레슨 카운트')
			}
		}).then(function (response) {
			console.log(response);
			if(response.data.error !== undefined && response.data.error.errorCode != '0')
			{
				return false;
				console.log(response.data.error.errorCode + " " + response.data.error.message);
			}
			else
			{
				sessionStorage.setItem('LessonbookCount', response.data.totalCount);
				if(sessionStorage.getItem('LessonbookCount') == null || sessionStorage.getItem('LessonbookCount') === undefined || sessionStorage.getItem('LessonbookCount') == 0)
				{
					$("#navigation").children().last().removeClass("new");
					$("#i_store_new").hide();
				}
				else
				{
					$("#navigation").children().last().addClass("new");
					$("#i_store_new").show();
				}
			}
			
		});
	}).catch(error => {
		return false;
	});
}