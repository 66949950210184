import React from 'react';
import Flag from "react-world-flags";
import * as lan from "../common/script/common_lan";

const ScoreRank = ({InfoData, InfoRankData, selectPlayerNum}) => {
    return (
        <div className={`score_rank ${InfoData.isNewRankingSystem ? 'new' : ''}`}>
            <div className="nickname">
                <p 
                    style={{
                        backgroundImage: "url(" + InfoRankData.cdn + InfoRankData.list[selectPlayerNum].userProfileImage + ")",
                }}>photo</p>
                {!!InfoRankData.list[selectPlayerNum].userCountry &&
                    <Flag code={InfoRankData.list[selectPlayerNum].userCountry} width="27" style={{ marginRight: "-1px", marginLeft: "7px" }} />
                }
                {InfoData.isNewRankingSystem &&
                    <>
                        {(InfoRankData.recordType === 'Stroke' || InfoRankData.recordType === 'NewPerio') ?
                            <i>
                                <img
                                    src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[selectPlayerNum].tournamentGradeType}_${InfoRankData.list[selectPlayerNum].tournamentStepType}.png`)}`}
                                        alt=''/>
                                </i>
                                :
                                <i>
                                    <img
                                        src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[selectPlayerNum].userGradeType}_${InfoRankData.list[selectPlayerNum].userStepType}.png`)}`}
                                        alt=''/>
                                </i>}
                        </>
                    }
                    <em>{InfoRankData.list[selectPlayerNum].userNickname}</em>
            </div>
            <div className="chart">
                <span>
                    <b>{lan.L('전체순위')}</b>
                    <strong
                        className={"rank" + InfoRankData.list[selectPlayerNum].totalRanking}>{InfoRankData.list[selectPlayerNum].totalRanking}</strong>
                </span>
                <span>
                    <b>{InfoRankData.list[selectPlayerNum].userGender === 0 ? lan.L('남자순위') : lan.L('여자순위')}</b>
                    <strong
                        className={"rank" + InfoRankData.list[selectPlayerNum].genderRanking}>{InfoRankData.list[selectPlayerNum].genderRanking}</strong>
                </span>
            </div>
        </div>
    );
};

export default ScoreRank;