import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as lan from '../common/script/common_lan.js';

export default function BottomMatchRank(prop){
	const selector = useSelector((state) => state);
	const dispatch = useDispatch();

	const goSelect = (e, value) => {
		if(prop.InfoData.isNewRankingSystem){
			dispatch({type:'match_courseNum', value: 'all'});
		}
		dispatch({type:'match_recordType', value: value});
		prop.slideExit();
	}
	
	const goSelectCourseId = (e, value) => {
		dispatch({type:'match_courseNum', value: value});
		prop.slideExit();
	}
    
	return (
		<>
        <div className="layerView" id="pop_recordType">
            <div className="bottom_sheet">
                <a onClick={prop.slideExit}>close</a>
                <div className="bs_body">
                <em>{lan.L('랭킹항목')}</em>
                <div className="scroll_container">
                    <div className="list_selection">
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'Score' && p.awardType === 'Stroke').length > 0 && (
						<a
						   data-item={'Stroke'}
						   data-value={prop.defaultvalue}
						   data-recordtype={selector.matchRankReducer.match_recordType}
						   data-checked={'Stroke' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'Stroke'}
								   id={"recordTypeStroke"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'Stroke')}
								   defaultChecked={'Stroke' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeStroke"}>{lan.L('Stroke')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'Score' && p.awardType === 'NewPerio').length > 0 && (
						<a
							data-item={'NewPerio'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'NewPerio' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'NewPerio'}
								   id={"recordTypeNewPerio"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'NewPerio')}
								   defaultChecked={'NewPerio' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeNewPerio"}>{lan.L('NewPerio')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'Score' && p.awardType === 'BirdieCount').length > 0 && (
						<a
							data-item={'BirdieCount'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'BirdieCount' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'BirdieCount'}
								   id={"recordTypeBirdieCount"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'BirdieCount')}
								   defaultChecked={'BirdieCount' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeBirdieCount"}>{lan.L('BirdieCount')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'Score' && p.awardType === 'EagleCount').length > 0 && (
						<a
							data-item={'EagleCount'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'EagleCount' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'EagleCount'}
								   id={"recordTypeEagleCount"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'EagleCount')}
								   defaultChecked={'EagleCount' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeEagleCount"}>{lan.L('EagleCount')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'Longest').length > 0 && (
						<a
							data-item={'Longest'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'Longest' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'Longest'}
								   id={"recordTypeLongest"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'Longest')}
								   defaultChecked={'Longest' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeLongest"}>{lan.L('Longest')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'Nearpin').length > 0 && (
						<a
							data-item={'Nearpin'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'Nearpin' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'Nearpin'}
								   id={"recordTypeNearpin"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'Nearpin')}
								   defaultChecked={'Nearpin' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeNearpin"}>{lan.L('Nearpin')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'HoleInOne').length > 0 && (
						<a
							data-item={'HoleInOne'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'HoleInOne' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'HoleInOne'}
								   id={"recordTypeHoleInOne"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'HoleInOne')}
								   defaultChecked={'HoleInOne' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeHoleInOne"}>{lan.L('HoleInOne')}</label>
						</a>
					)}
					{prop.InfoData.awards?.filter((p) => p.awardItems === 'CourseRoundPlayCount').length > 0 && (
						<a
							data-item={'CourseRoundPlayCount'}
							data-value={prop.defaultvalue}
							data-recordtype={selector.matchRankReducer.match_recordType}
							data-checked={'CourseRoundPlayCount' === selector.matchRankReducer.match_recordType}>
							<input type="radio" value={'CourseRoundPlayCount'}
								   id={"recordTypeCourseRoundPlayCount"}
								   name={"bottomRecordType"}
								   onClick={(e) => goSelect(e, 'CourseRoundPlayCount')}
								   defaultChecked={'CourseRoundPlayCount' === selector.matchRankReducer.match_recordType}/>
							<label
								htmlFor={"recordTypeCourseRoundPlayCount"}>{lan.L('CourseRoundPlayCount')}</label>
						</a>
					)}
					</div>
				</div>
				</div>
			</div>
		</div>

		<div className="layerView" id="pop_courseId" data-course={selector.matchRankReducer.match_courseNum}>
			<div className="bottom_sheet">
				<a onClick={prop.slideExit}>close</a>
				<div className="bs_body">
					{/*<em>{lan.L('코스항목')}</em>*/}
					<div className="scroll_container">
						<div className="list_selection">
							{prop.InfoData.isNewRankingSystem &&
								<a data-checked={selector.matchRankReducer.match_courseNum === 'all'}>
									<input type="radio" value={'all'} id={"courseIdAll"}
										   name={"bottomcourseId"}
										   onClick={(e) => goSelectCourseId(e, 'all')} defaultChecked={selector.matchRankReducer.match_courseNum === 'all'}/>
									<label htmlFor={"courseIdAll"}>{lan.L('합산')}</label>
								</a>
							}
							{(!prop.InfoData.isNewRankingSystem || ['Stroke', 'NewPerio'].includes(selector.matchRankReducer.match_recordType)) &&
								prop.InfoData.courseRounds?.map((item, i) =>
								<a key={i+"b"} data-checked={selector.matchRankReducer.match_courseNum === i} >
								  <input type="radio" value={i} id={"courseId"+i}
									name={"bottomcourseId"}
									onClick={(e) => goSelectCourseId(e, i)} checked={selector.matchRankReducer.match_courseNum === 'all' ? false : selector.matchRankReducer.match_courseNum === i}/>
								  <label htmlFor={"courseId"+i}>
									  {prop.InfoData.isNewRankingSystem &&
										  `${lan.L('코스')}${i+1}.`
									  }
									  {lan.L(item.courseName)}
								  </label>
								</a>
								)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}