import React from 'react';
import * as lan from "../common/script/common_lan";
import ScoreRank from "./score_rank";
import StrokeRanking from "./stroke_ranking";
import StrokeTotalRanking from "./stroke_total_ranking";
import NewperioRanking from "./newperio_ranking";
import NewperioTotalRanking from "./newperio_total_ranking";
import * as common from "../common/script/common";

const RankingBottomSheet = ({ id, InfoData, InfoRankData, selectPlayerNum, slideExit, selector }) => {
    const recordType = InfoRankData.recordType;
    const courseIndex = selector.matchRankReducer.match_courseNum;
    const isTotalView = courseIndex === 'all';
    const isStroke = recordType === 'Stroke';
    const isNewPerio = recordType === 'NewPerio';

    const selectedPlayer = InfoRankData.list[selectPlayerNum];
    const selectedCourse = InfoData.courseRounds.find(c => c.courseId === selectedPlayer?.courseId);
    const selectedHole = selectedCourse?.holes?.[selectedPlayer?.holeNumber - 1];

    const displayCourse = InfoData.courseRounds?.[courseIndex];

    const titleMap = {
        Stroke: InfoData.isNewRankingSystem && !isTotalView ? displayCourse?.courseName : `${lan.L('스트로크')} ${lan.L('랭킹')}`,
        NewPerio: InfoData.isNewRankingSystem && !isTotalView ? displayCourse?.courseName : `${lan.L('신페리오 랭킹')}`,
        Longest: `${lan.L('롱기스트 랭킹')}`,
        Nearpin: `${lan.L('니어핀 랭킹')}`,
        BirdieCount: `${lan.L('버디 횟수')} ${lan.L('랭킹')}`,
        EagleCount: `${lan.L('이글 횟수')} ${lan.L('랭킹')}`,
        HoleInOne: `${lan.L('홀인원')} ${lan.L('랭킹')}`,
        CourseRoundPlayCount: `${lan.L('라운딩 횟수')} ${lan.L('랭킹')}`
    };

    const dateFormatted = (ts) => (
        <>
            {common.UnixTime(ts, '년.월.일')}<br />
            {common.UnixTime(ts, '시:분:초')}
        </>
    );

    const renderDetailContent = () => {
        if (isStroke) {
            return isTotalView ? (
                <StrokeTotalRanking InfoRankData={InfoRankData} selectPlayerNum={selectPlayerNum} />
            ) : selectedPlayer.holeScore && (
                <StrokeRanking
                    InfoData={InfoData}
                    InfoRankData={InfoRankData}
                    matchCourseNum={courseIndex}
                    selectPlayerNum={selectPlayerNum}
                />
            );
        }

        if (isNewPerio) {
            return isTotalView ? (
                <NewperioTotalRanking InfoRankData={InfoRankData} selectPlayerNum={selectPlayerNum} />
            ) : selectedPlayer.holeScore && (
                <NewperioRanking
                    InfoData={InfoData}
                    InfoRankData={InfoRankData}
                    matchCourseNum={courseIndex}
                    selectPlayerNum={selectPlayerNum}
                />
            );
        }

        const table = {
            Longest: (
                <table className="score_chart">
                    <thead><tr>
                        <th>{lan.L('Course')}</th><th>{lan.L('전장거리')}</th><th>{lan.L('비거리')}</th><th>{lan.L('달성일')}</th>
                    </tr></thead>
                    <tbody><tr>
                        <td>{InfoData.courseRounds[0].courseName}</td>
                        <td><b>{common.Length(InfoData.courseRounds[0].totalDistance, '0m')}</b></td>
                        <td><b>{common.Length(selectedPlayer?.record, '0m')}</b></td>
                        <td>{dateFormatted(selectedPlayer?.recordTime)}</td>
                    </tr></tbody>
                </table>
            ),
            Nearpin: (
                <table className="score_chart">
                    <thead><tr>
                        <th>{lan.L('Course')}</th><th>{lan.L('Hole')}</th><th>{lan.L('전장거리')}</th><th>{lan.L('남은거리')}</th><th>{lan.L('달성일')}</th>
                    </tr></thead>
                    <tbody><tr>
                        <td>{InfoData.courseRounds[0].courseName}</td>
                        <td><b>{InfoData.courseRounds[0].nearpinHoleNumber}</b></td>
                        <td><b>{common.Length(InfoData.courseRounds[0].totalDistance, '0m')}</b></td>
                        <td><b>{common.Length(selectedPlayer?.record, '0m')}</b></td>
                        <td>{dateFormatted(selectedPlayer?.recordTime)}</td>
                    </tr></tbody>
                </table>
            ),
            BirdieCount: (
                <table className="score_chart">
                    <thead><tr><th>{lan.L('Count')}</th></tr></thead>
                    <tbody><tr>
                        <td><b>{selectedPlayer?.birdieCount}</b></td>
                    </tr></tbody>
                </table>
            ),
            EagleCount: (
                <table className="score_chart">
                    <thead><tr><th>{lan.L('Count')}</th></tr></thead>
                    <tbody><tr>
                        <td><b>{selectedPlayer?.eagleCount}</b></td>
                    </tr></tbody>
                </table>
            ),
            HoleInOne: (
                <table className="score_chart">
                    <thead><tr>
                        <th>{lan.L('Course')}</th><th>{lan.L('Hole')}</th><th>{lan.L('전장거리')}</th><th>{lan.L('달성일')}</th>
                    </tr></thead>
                    <tbody><tr>
                        <td>{selectedCourse?.courseName}</td>
                        <td><b>{selectedPlayer?.holeNumber}</b></td>
                        <td><b>{common.Length(selectedHole?.blackTeeDistance, '0m')}</b></td>
                        <td>{dateFormatted(selectedPlayer?.recordTime)}</td>
                    </tr></tbody>
                </table>
            ),
            CourseRoundPlayCount: (
                <table className="score_chart">
                    <thead><tr><th>{lan.L('Count')}</th><th>{lan.L('마지막 라운드 시작 시간')}</th></tr></thead>
                    <tbody><tr>
                        <td><b>{selectedPlayer?.totalCourseRoundPlayCount}</b></td>
                        <td><b>{common.UnixTime(selectedPlayer?.recordTime, '년.월.일 시:분:초')}</b></td>
                    </tr></tbody>
                </table>
            )
        };

        return table[recordType] || null;
    };

    return (
        <div id={id} className="layerView">
            <div className="bottom_sheet">
                <a onClick={slideExit}>close</a>
                <div className="bs_body">
                    <em>{titleMap[recordType]}</em>
                    <div className="scroll_container">
                        <ScoreRank InfoData={InfoData} InfoRankData={InfoRankData} selectPlayerNum={selectPlayerNum} />
                        {renderDetailContent()}
                    </div>
                </div>
                <div className="bottom_sheet_button">
                    <button type="button" className="btn_standard" onClick={slideExit}>{lan.L('확인')}</button>
                </div>
            </div>
        </div>
    );
};

export default RankingBottomSheet;
