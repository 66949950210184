import * as lan from './common/script/common_lan.js';

import React from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { createStore } from "redux";
import {BrowserRouter, HashRouter, Routes, Route, useNavigate} from "react-router-dom";
import {Helmet} from 'react-helmet';
import './common/css/mobile_default.css';
import './common/css/NotoSansKR.css';
import './common/css/Roboto.css';

import * as redux from './reducer/reduxindex';

import Wrap from './inc/wrap';
import LoginCheck from './inc/loginCheck';
import Top from './inc/top';
import Tab from './inc/tab';
import Navigation from './inc/navigation';
import FullMenu from './inc/fullMenu';

import Store from './store/store';
import DetailStore from './store/detail_store';
import DetailStoreLink from './store/detail_store_link';
import PictueView from './store/picture_view';

import Statistic from './statistic/statistic';
import DetailDriving from './statistic/detail_driving';
import DetailApproach from './statistic/detail_approach';
import DetailCurved from './statistic/detail_curved';
import DetailShortgame from './statistic/detail_shortgame';
import DetailStraight from './statistic/detail_straight';

import Default from './default';
import Intro from './intro';

import Login from './login/login';
import FindID from './login/find_id';
import ResultID from './login/result_id';
import FindPW from './login/find_pw';
import ResetPW from './login/reset_pw';

import Agreement from './join/agreement';
import Addition from './join/addition';
import Address from './join/address';
import Completion from './join/completion';
import Nickname from './join/nickname';
import Phonenumber from './join/phonenumber';
import Register_id from './join/register_id';
import Roundset from './join/roundset';

import Agreement01 from './terms/agreement01';
import Agreement02 from './terms/agreement02';
import Agreement03 from './terms/agreement03';
import Agreement04 from './terms/agreement04';

import Agreement01Kor from './terms/agreement01Kor';
import Agreement02Kor from './terms/agreement02Kor';

import Record from './record/record';
import DetailStroke from './record/detail_stroke';
import Foursome from './record/detail_foursome';
import ShotVideo from './shot/shotvideo';
import DetailVideo from './shot/detail_video';
import DetailVideoPractice from './shot/detail_video_practice';
import Game from './match/game';
import DetailMatch from './match/detail_match';
import Status from './reserve/status';
import ListBoard from './customer/list_board';
import ViewNotice from './customer/view_notice';
import ViewEvent from './customer/view_event';
import Setting from './setup/setting';

import Myinfo from './setup/myinfo';
import ModifyNickName from './setup/modify_nickname';
import ModifyPhone from './setup/modify_phone';
import ModifyAddress from './setup/modify_address';
import ModifyAddition from './setup/modify_addition';
import ModifyRound from './setup/modify_round';
import Withdrawal from './setup/withdrawal';
import WithdrawalInfo from './setup/withdrawal_info';
import PasswordCurrent from './setup/password_current';
import PasswordNew from './setup/password_new';

import PopupDaumPost from './popup/popup_daumpost';
import PopupDom from './popup/popup_dom';
import PopupAlert from './popup/popup_alert';
import PopupChoice from './popup/popup_choice';

import BackNavigation from './common/script/backnavigation';
import NotFound from './common/script/notFound';
import CommonGoogleMap from './common/script/common_googlemap';
import Payment from './payment/payment'

import { GoogleOAuthProvider} from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const statustab = [{tabName:'예약현황'},{tabName:'이용현황'}]

root.className = "wrap"

const store = createStore(redux.rootReducer);

root.render(
	<GoogleOAuthProvider clientId="557292483227-3ecd2chct34nnda7o27k08908r02o7ao.apps.googleusercontent.com">
	<Provider store={store}>
	<HashRouter>
		<Wrap>
			<PopupAlert/>
			<PopupChoice/>
			<Routes>
				<Route exact path="/" element={<><Top exit={"Y"}/><Intro /></>}>
				</Route>
				<Route path="/login/login" element={<><Top exit={"Y"}/><Login /></>} />
				<Route path="/login/find_id" element={<><FindID /></>} />
				<Route path="/login/result_id" element={<><Top class="topfix" title={"아이디찾기 결과"}/><ResultID /></>} />
				<Route path="/login/find_pw" element={<><FindPW /></>} />
				<Route path="/login/reset_pw" element={<><Top class="topfix" title={"비밀번호 재설정"}/><ResetPW /></>} />

				<Route path="/default/default" element={<><LoginCheck/><Top exit={"Y"}/><Navigation toggle={0}/><FullMenu/> <Default/></>} />
				<Route path="/store/store" element={<><LoginCheck/><Navigation toggle={1}/><FullMenu/> <Top class="topfix" title={"매장찾기"}/> <Store /></>} />
				<Route path="/store/detail_store" element={<> <LoginCheck/><DetailStore /></>} />
				<Route path="/store/detail_store_link" element={<> <DetailStoreLink /></>} />
				<Route path="/store/picture_view" element={<><Top class="topfix" title={"매장사진"}/> <PictueView/></>} />

				<Route path="/statistic/statistic" element={<><LoginCheck/><Navigation toggle={3}/><FullMenu/> <Top class="topfix" title={"통계 정보"}/> <Statistic /></>} />
				<Route path="/statistic/detail_driving" element={<><LoginCheck/><DetailDriving /></>} />
				<Route path="/statistic/detail_approach" element={<><LoginCheck/><DetailApproach /></>} />
				<Route path="/statistic/detail_curved" element={<><LoginCheck/><DetailCurved /></>} />
				<Route path="/statistic/detail_shortgame" element={<><LoginCheck/><DetailShortgame /></>} />
				<Route path="/statistic/detail_straight" element={<><LoginCheck/><DetailStraight /></>} />
				
				<Route path="/record/record" element={<><LoginCheck/><Top class="topfix" title={"나의 기록실"}/> <Record></Record> </>} />
				<Route path="/record/detail_stroke" element={<><LoginCheck/><Top class="convert turn" title={"스트로크 라운드 기록"}/> <DetailStroke></DetailStroke> </>} />
				<Route path="/record/detail_foursome" element={<><LoginCheck/><Top class="topfix" title={"포섬 라운드 기록"}/> <Foursome></Foursome> </>} />

				<Route path="/shot/shotvideo" element={<><LoginCheck/><Top class="topfix exception1" title={"샷 정보"}/> <ShotVideo></ShotVideo> </>} />
				<Route path="/shot/detail_video" element={<><LoginCheck/><DetailVideo></DetailVideo> </>} />
				<Route path="/shot/detail_video_practice" element={<><LoginCheck/><DetailVideoPractice></DetailVideoPractice> </>} />

				<Route path="/match/game" element={<><LoginCheck/><Top class="topfix" title={"대회"}/> <Game></Game> </>} />
				<Route path="/match/detail_match" element={<><LoginCheck/><Top class="convert turn" title={"대회 상세 보기"} to="/match/game"/> <DetailMatch></DetailMatch> </>} />

				<Route path="/reserve/status" element={<><LoginCheck/><Top class="topfix" title={"매장 예약정보"}/> <Tab topics={statustab} defaultTopics={0} tabkey={"매장 예약정보"}></Tab> <Status></Status> </>} />
				<Route path="/customer/list_board" element={<><LoginCheck/><Top class="topfix" title={"공지사항"}/> <FullMenu/><ListBoard></ListBoard></>} />
				<Route path="/customer/view_notice" element={<><LoginCheck/><Top class="topfix" title={"공지"} to="/customer/list_board"/>  <ViewNotice></ViewNotice> </>} />
				<Route path="/customer/view_event" element={<><LoginCheck/><Top class="topfix" title={"이벤트"} to="/customer/list_board"/>  <ViewEvent></ViewEvent> </>} />

				<Route path="/join/agreement"		 element={<><Top class="topfix" title={"회원가입"}/> <Agreement></Agreement> </>} />
				<Route path="/terms/agreement01"element={<><Top class="topfix"spanClass="longtitle" title={"회원 이용약관"}/> <Agreement01></Agreement01> </>} />
				<Route path="/terms/agreement01Kor"element={<><Agreement01Kor></Agreement01Kor> </>} />
				<Route path="/terms/agreement02"element={<><Top class="topfix" spanClass="longtitle" title={"개인정보 보호정책"}/> <Agreement02></Agreement02> </>} />
				<Route path="/terms/agreement02Kor"element={<><Agreement02Kor></Agreement02Kor> </>} />
				<Route path="/terms/agreement03"element={<><Top class="topfix" spanClass="longtitle" title={"제3자 정보제공 및 마케팅 수신 동의"}/> <Agreement03></Agreement03> </>} />
				<Route path="/terms/agreement04"element={<><Top class="topfix" spanClass="longtitle" title={"위치 기반 서비스 이용약관"}/> <Agreement04></Agreement04> </>} />
				
				<Route path="/join/register_id"	 element={<><Top class="topfix" title={"회원가입"} type="join" /> <Register_id></Register_id> </>} />
				<Route path="/join/nickname"		 element={<><Top class="topfix" title={"회원가입"} type="join" /> <Nickname></Nickname> </>} />
				<Route path="/join/addition"		 element={<><Top class="topfix" title={"회원가입"} type="join" />  <Addition></Addition> </>} />
				<Route path="/join/phonenumber" element={<><Phonenumber></Phonenumber> </>} />
				<Route path="/join/completion"	 element={<><Completion></Completion> </>} />
				<Route path="/join/address"		 element={<><Top class="topfix" title={"상세정보입력"} type="joinaddition"/> <Address></Address> </>} />
				<Route path="/join/roundset"		 element={<><Top class="topfix" title={"상세정보입력"} type="joinaddition"/> <Roundset></Roundset> </>} />

				<Route path="/setup/setting" element={<><LoginCheck/><Top class="topfix" title={"설정"}/> <Setting></Setting> </>} />

				<Route path="/setup/myinfo" element={<><LoginCheck/><Top class="topfix" title={"내 정보"}/> <Myinfo></Myinfo> </>} />
				<Route path="/setup/modify_nickname" element={<><LoginCheck/><Top class="topfix" title={"기본정보 수정"}/> <ModifyNickName></ModifyNickName> </>} />
				<Route path="/setup/modify_phone" element={<><LoginCheck/><ModifyPhone></ModifyPhone> </>} />
				<Route path="/setup/modify_address" element={<><LoginCheck/><Top class="topfix" title={"기본정보 수정"}/> <ModifyAddress></ModifyAddress> </>} />
				<Route path="/setup/modify_addition" element={<><LoginCheck/><Top class="topfix" title={"추가정보 수정"}/> <ModifyAddition></ModifyAddition> </>} />
				<Route path="/setup/modify_round" element={<><LoginCheck/><Top class="topfix" title={"라운드 설정"}/> <ModifyRound></ModifyRound> </>} />
				<Route path="/setup/withdrawal" element={<><LoginCheck/><Top class="topfix" title={"회원 탈퇴"}/> <Withdrawal></Withdrawal> </>} />
				<Route path="/setup/withdrawal_info" element={<><Top class="topfix" title={"회원 탈퇴"}/> <WithdrawalInfo></WithdrawalInfo> </>} />
				<Route path="/setup/password_current" element={<><LoginCheck/><Top class="topfix" title={"비밀번호 변경"}/> <PasswordCurrent></PasswordCurrent> </>} />
				<Route path="/setup/password_new" element={<><LoginCheck/><Top class="topfix" title={"비밀번호 변경"}/> <PasswordNew></PasswordNew> </>} />

				<Route path="/popup/popup_daumpost" element={<><Top class="topfix" title={"주소 검색 "}/><PopupDom><PopupDaumPost/></PopupDom></>} />

				<Route path="/pay/course" element={<> <Payment></Payment> </>} />
				
				<Route path="/common/script/backnavigation" element={<><BackNavigation/></>} />
				<Route path="/*" element={<><NotFound/></>} />
				<Route path="/index.html" element={<><NotFound/></>} />
				<Route path="*" element={<><NotFound/></>} />
			</Routes>
		</Wrap>
	</HashRouter>
	</Provider>
	</GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
	