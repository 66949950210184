import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import * as lan from '../common/script/common_lan.js';
import axios from 'axios';
import { showAlert } from '../common/script/common';

export default function Payment(){
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [isReady, setIsReady] = useState(false);

	const loadExternalScript = (src, id) => {
		return new Promise((resolve, reject) => {
			if (document.getElementById(id)) return resolve();
			const script = document.createElement('script');
			script.src = src;
			script.async = true;
			script.id = id;
			script.onload = resolve;
			script.onerror = reject;
			document.head.appendChild(script);
		});
	};

	useEffect(() => {
		const fetchAndPrepareScript = async () => {
			try {
				const testPayload = {
					paymentMethodType: "CreditCard",
					itemId: "10111011011012",
					quantity: 1,
					totalPrice: 110
				};

				const res = await axios.post('https://qa-billing.thekgolf.com/v2/payment/domestic', testPayload, {
					headers: {
						'Content-Type': 'application/json',
						'x-thekgolf-timezone': '+09:00',
						'x-thekgolf-language': 'ko-kr',
						'Authorization': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IkVFOEMzRDM5RkExM0ExOUQxNzIwQjBFNDYzMzc1NzhFRTYxNkQ5NzQiLCJ0eXAiOiJhdCtqd3QiLCJ4NXQiOiI3b3c5T2ZvVG9aMFhJTERrWXpkWGp1WVcyWFEifQ.eyJuYmYiOjE3NDM2NDU0MTEsImV4cCI6MTc0MzczMTgxMSwiaXNzIjoiaHR0cHM6Ly9xYS1hdXRoLnRoZWtnb2xmLmNvbSIsImF1ZCI6IlNob3BfQXV0aCIsImNsaWVudF9pZCI6InRoZWtnb2xmX3Nob3BfcWEiLCJzdWIiOiIxIiwiYXV0aF90aW1lIjoxNzQzNjQ1NDExLCJpZHAiOiJUaGVLR29sZl9TaG9wIiwicm9sZSI6IlNob3BNYW5hZ2VyIiwiaWR4IjoiMSIsIm1hbmFnZXJJZCI6ImdzYml6MSIsIm1hbmFnZXJOYW1lIjoiR1NCSVoiLCJtYW5hZ2VyVHlwZSI6Im1hbmFnZXJfdHlwZV9jZDphZG1pbiIsInNob3BHdWlkIjoiODBmZCIsInNob3BOYW1lIjoiR1NCSVoxIiwiY291bnRyeSI6IktSIiwiaXNTaG9wT3duZXIiOiJ0cnVlIiwic2NvcGUiOlsib3BlbmlkIiwiU2hvcF9BdXRoIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbImN1c3RvbSJdfQ.HNpp-D6XJe9dtycvrWMDtGc5VJAolE-HC9wtfqZg-ZODY6m8MkDn0hdxCKRtV5bJ3csyMgGC9dTbAcFt7-SmiFIrhDN-4ge20JbMMnP0Q0j7MN5jA08KboWi34FGE0vPH0C7tri1VNT-01YCQzdTkbH81d37iZ2RzAeqyUfdw7JngqDZSb2idQETq62FizoIdsAzD0XI6FgG50mdfgjF7Ee9LMXP72axiSWKS9V9pmgfhZ7Ly1C7m-sJNgADUXkIOjqvftXJSw93xhpmHZuiMPXbYmP0L-f1Je-7kgrs0hfyzJF5f5bTg317oRsH_pFpP5EL_xgqlV8ds1vSyNa6SQ' // Replace with actual token
					}
				});

				console.log("Server response:", res.data);

				if (res.data && typeof res.data.script === 'string') {
					const tempDiv = document.createElement('div');
					tempDiv.innerHTML = res.data.script;

					const embeddedScript = tempDiv.querySelector('script');
					if (embeddedScript) {
						const execScript = document.createElement('script');
						execScript.text = embeddedScript.innerText;
						document.body.appendChild(execScript);
					}

					if (res.data.html) {
						document.getElementById('payment-container').innerHTML = res.data.html;
					}

					await loadExternalScript(process.env.REACT_APP_SETTLEBANK_SCRIPT, "settlebank-core-script");
					await loadExternalScript(process.env.REACT_APP_SETTLEBANK_BANK_SCRIPT, "settlebank-bank-script");

					setIsReady(true);
				} else {
					throw new Error('Response is not a valid script');
				}
			} catch (error) {
				console.error(error);
				showAlert(lan.L('Payment initialization failed'));
				navigate('/error');
			} finally {
				setLoading(false);
			}
		};

		fetchAndPrepareScript();

	}, [location, navigate]);

	const handleStartPayment = () => {
		if (typeof window.fnCreditCardPaymentRequest === 'function') {
			window.fnCreditCardPaymentRequest();
		} else {
			console.warn("fnCreditCardPaymentRequest is not defined");
		}
	};

	return (
		<>
			<header>
				<em>{lan.L('Payment')}</em>
			</header>
			{loading && <div>{lan.L('Loading...')}</div>}
			<div id="payment-container"></div>
			{isReady && (
				<button
					onClick={handleStartPayment}
					disabled={loading}
					className="bg-blue-600 text-white px-4 py-2 rounded mt-4"
				>
					{lan.L('Start Payment')}
				</button>
			)}
		</>
	);
}