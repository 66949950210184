import * as lan from '../common/script/common_lan.js';

import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as common from '../common/script/common.js';
import $ from 'jquery';
import axios from 'axios';

import MatchTab from '../inc/match_tab';
import RoundSettingInformation from "./round_setting_information";
import CourseRoundsInformation from "./course_rounds_information";
import RankingBottomSheet from "./ranking_bottom_sheet";
import BottomMatchRank from '../popup/bottom_matchrank.js';
import BottomMatchAward from '../popup/bottom_matchaward.js';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function DetailMatch(props) {
    const location = useLocation();
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();

    const [InfoData, SetInfoData] = useState([]);
    const [tempInfoData, setTempInfoData] = useState([]);
    const [InfoRankData, SetInfoRankData] = useState({list: []});
    const [IsLoading, setIsLoading] = useState(false);
    const [InfoAwardData, SetInfoAwardData] = useState([]);
    const [groupedByGradeType, setGroupedByGradeType] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [myrankingnum, setmyrankingnum] = useState(-1);
    const [myCourseData, setMyCourseData] = useState({});

    // 페이지네이션 관련 state 추가
    const currentPageRef = useRef(1);
    const [totalPages, setTotalPages] = useState(1); // 전체 페이지 수
    const [hasMore, setHasMore] = useState(true); // 추가 데이터가 있는지 여부
    const pageSize = 50; // 한 페이지당 항목 수

    const [isMyAwardShow, setIsMyAwardShow] = useState(false);
    const [isnomyward, setisnomyward] = useState(false);
    const [myawardboollist, setmyawardboollist] = useState(false);
    const [isNewRankingSystem, setIsNewRankingSystem] = useState(false);

    const [dateType, setDateType] = useState('');
    const [dateText, setDateText] = useState('');

    const [selectPlayerNum, setSelectPlayerNum] = useState(0);
    const [show, setShow] = useState(false);

    //최초실행
    useEffect(() => {
        const storageHandler = () => {
            const tabNum = parseInt(window.localStorage.getItem('tabNum'));
            setActiveTab(tabNum);
        };
        window.addEventListener('storage', storageHandler);
        return () => {
            window.removeEventListener('storage', storageHandler);
        };
    }, []);

    //화면전환시 실행
    useEffect(() => {
        dispatch({type: 'match_initstate', value: ''});
        goData(); //데이터 불러오기
        // goAward();
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            setShow(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // 스크롤 감지
    useEffect(() => {
        const scrollHandler = () => {
            if (IsLoading || !hasMore) return; // 더 이상 불러올 데이터가 없으면 리스너 추가 X

            if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100) {

                if (['CourseRoundPlayCount', 'HoleInOne'].includes(selector.matchRankReducer.match_recordType)) {
                    setIsLoading(true);
                    goTotalRanking(currentPageRef.current + 1)
                        .then(() => setIsLoading(false))
                        .catch(() => setIsLoading(false));
                } else {
                    if (selector.matchRankReducer.match_courseNum !== 0 || tempInfoData.courseRounds?.length > 0) {
                        setIsLoading(true);
                        goRanking(currentPageRef.current + 1)
                            .then(() => setIsLoading(false))
                            .catch(() => setIsLoading(false));
                    } else {
                        console.warn('courseRounds가 아직 로드되지 않음! 스크롤 이벤트 중단');
                    }
                }
            }
        };

        window.addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [IsLoading, hasMore, selector.matchRankReducer.match_courseNum, tempInfoData]);

    // activeTab 또는 match_recordType 변경 시 데이터 리셋하고 새로운 데이터를 불러오는 역할
    useEffect(() => {
        setSelectPlayerNum(0);

        if (activeTab === 1) {
            SetInfoRankData({list: []});
            setHasMore(true);
            currentPageRef.current = 1;

            if (['CourseRoundPlayCount', 'HoleInOne'].includes(selector.matchRankReducer.match_recordType)) {
                goTotalRanking(1, true);
            } else {

                goRanking(1, true);
            }
        } else if (activeTab === 2) {
            goAward();
        }
    }, [activeTab, selector.matchRankReducer.match_recordType]);

    // 내 랭킹 찾기
    useEffect(() => {
        if (myrankingnum > -1 && InfoRankData.list.length > 0) {
            const courseData = InfoData.courseRounds?.find((p) => p.courseId === InfoRankData.list[myrankingnum].courseId);
            setMyCourseData(courseData);
        }
    }, [InfoData, InfoRankData, myrankingnum]);

    // selector가 변경될때 상시 실행 종료
    useEffect(() => {
        if (isNewRankingSystem && (
            selector.matchRankReducer.match_recordType === 'Stroke' ||
            selector.matchRankReducer.match_recordType === 'NewPerio' ||
            selector.matchRankReducer.match_recordType === 'BirdieCount' ||
            selector.matchRankReducer.match_recordType === 'EagleCount' ||
            selector.matchRankReducer.match_recordType === 'HoleInOne' ||
            selector.matchRankReducer.match_recordType === 'CourseRoundPlayCount'
        )) {
            dispatch({type: 'match_courseNum', value: 'all'});
        } else {
            dispatch({type: 'match_courseNum', value: 0});
        }
    }, [isNewRankingSystem, selector.matchRankReducer.match_recordType, dispatch]);

    //Reducer값을 초기화 진행(탭 변경시 초기화)
    const initReducer = () => {
        goData(); //데이터 불러오기
        goAward();
    }

    const goData = () => {
        //대회 상세 정보 조회
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language': sessionStorage.getItem('Language'),
                'pUrl': encodeURIComponent('대회 상세 정보 조회'),
                'guid': location.state.tournamentId,
                'token': sessionStorage.getItem('access_token')
            }
        }).then(function (response) {
            const grades = response.data.participationGrades;
            const group = grades.reduce((acc, curr) => {
                const {gradeType, ...rest} = curr;
                const foundIndex = acc.findIndex(item => item.gradeType === gradeType);
                if (foundIndex !== -1) {
                    acc[foundIndex].data.push(rest);
                } else {
                    acc.push({gradeType, data: [rest]});
                }
                return acc;
            }, []);

            setGroupedByGradeType(group)
            if (response.data.error !== undefined && response.data.error.code > 0) {
                common.alertByError('대회 상세 목록', response.data.error.code);
                return false;
            } else {
                SetInfoData(response.data);
                setTempInfoData(response.data);
                setIsNewRankingSystem(response.data.isNewRankingSystem);
                if (response.data.awards[0].awardItems === 'Score') {
                    dispatch({type: 'match_recordType', value: response.data.awards[0].awardType});
                } else {
                    dispatch({type: 'match_recordType', value: response.data.awards[0].awardItems});
                }

                const now = Date.now();
                //날짜 비교해서 예정인지 종료인지 파악
                if (now < response.data.startTime) //예정
                {
                    setDateText(lan.L("예정") + " D-" + common.UnixTime(response.data.startTime - now, '일자'));
                    setDateType("expect");
                } else if (now < response.data.endTime) //진행중
                {
                    setDateText(lan.L("진행중") + " D-" + common.UnixTime(now - response.data.startTime, '일자'));
                    setDateType("ongoing");
                } else //종료
                {
                    setDateText(lan.L("종료"));
                    setDateType("ending");
                }
            }
        }).catch(error => {
            console.log(error)
        });
        //대회 상세 정보 조회 종료
    }

    const goRanking = async (page, reset = false) => {
        if (!reset && (IsLoading || !hasMore)) return;

        let courseId;
        if (selector.matchRankReducer.match_courseNum === 'all') {
            if (selector.matchRankReducer.match_recordType === 'Stroke'
                || selector.matchRankReducer.match_recordType === 'NewPerio'
            ) {
                courseId = 'ALLAWARD';
            } else if (selector.matchRankReducer.match_recordType === 'BirdieCount' || selector.matchRankReducer.match_recordType === 'EagleCount') {
                courseId = 'ALL'
            } else {
                courseId = tempInfoData.courseRounds[0]?.courseId || '';
            }
        } else {
            courseId = tempInfoData.courseRounds?.[selector.matchRankReducer.match_courseNum]?.courseId || '';
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER + '/api',
                {page, pageSize},
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'language': sessionStorage.getItem('Language'),
                        'pUrl': encodeURIComponent('대회 랭킹 조회'),
                        'guid': location.state.tournamentId,
                        'guid2': selector.matchRankReducer.match_recordType,
                        'guid3': courseId,
                    }
                });

            const data = response.data;

            if (data.error && data.error.code > 0) {
                if (parseInt(window.localStorage.getItem("tabNum")) === 1) {
                    if (data.error.code !== 7660) {
                        console.error("대회 상세 목록 에러:", data.error.code);
                    } else {
                        $("#noRankingData").show();
                        $("#RankingDataDiv").hide();
                        $("#ranking_list").hide();
                    }
                    return;
                }
            }

            // ✅ 데이터가 없거나 `list`가 undefined일 경우 방어 코드
            const newList = data.list ?? [];
            if (newList.length === 0) {
                setHasMore(false);
                return;
            }
            const totalItemCount = data.totalCount ?? 0;
            const totalPageCount = Math.ceil(totalItemCount / pageSize);

            // 🚨 신페리오(NewPerio) 랭킹이 "진행 중"일 때 숨김 처리
            if (selector.matchRankReducer.match_recordType === "NewPerio" && data.datetype === "ongoing") {
                $("#noRankingData").show();
                $("#RankingDataDiv").hide();
                $("#ranking_list").hide();
            } else if (data.list.length > 0) {
                $("#noRankingData").hide();
                $("#RankingDataDiv").show();
                $("#ranking_list").show();
            } else {
                $("#noRankingData").show();
                $("#RankingDataDiv").hide();
                $("#ranking_list").hide();
            }

            // 내 랭킹 찾기
            const myRankIndex = newList.findIndex(
                (item) => item.userNickname === sessionStorage.getItem('nickname')
            );
            setmyrankingnum(myRankIndex);
            setHasMore(page < totalPageCount);
            setTotalPages(totalPageCount);
            currentPageRef.current = page;

            if (reset) {
                // reset 시 기존 데이터를 대체
                SetInfoRankData({...data, list: newList});
            } else {
                // 기존 데이터와 병합 (중복 제거)
                SetInfoRankData((prev) => ({
                    ...data,
                    list: [
                        ...prev.list,
                        ...newList.filter(item => !prev.list.some(prevItem => prevItem.userNickname === item.userNickname))
                    ]
                }));
            }

        } catch (error) {
            console.error("대회 랭킹 조회 오류:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // 기존 goTotalRanking 함수를 페이지네이션 + infiniteScroll 방식으로 수정
    const goTotalRanking = async (page, reset = false) => {
        if (!reset && (IsLoading || !hasMore)) return;

        setIsLoading(true);

        try {
            // 1) 서버에 page, pageSize 전달
            const response = await axios.post(process.env.REACT_APP_SERVER + '/api', {
                page,
                pageSize,
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'language': sessionStorage.getItem('Language'),
                    'pUrl': encodeURIComponent('대회 종합 랭킹 조회'),
                    'guid': location.state.tournamentId,
                    'guid2': selector.matchRankReducer.match_recordType
                }
            });

            const data = response.data;

            // 2) 에러 처리
            if (data.error && data.error.code > 0) {
                if (parseInt(window.localStorage.getItem('tabNum')) === 1) {
                    if (data.error.code !== 7672) {
                        common.alertByError('대회 상세 목록', data.error.code);
                    } else {
                        $("#noRankingData").show();
                        $("#RankingDataDiv").hide();
                        $("#ranking_list").hide();
                    }
                    return;
                }
            }

            // 3) 새로 불러온 랭킹 목록
            const newList = data.list ?? [];
            if (newList.length === 0) {
                // 새 데이터가 없으면 더 이상 불러올 게 없다고 처리
                setHasMore(false);
                return;
            }

            // totalCount로 전체 페이지 계산
            const totalItemCount = data.totalCount ?? 0;
            const totalPageCount = Math.ceil(totalItemCount / pageSize);

            // 4) 신페리오 "진행중" 숨김 처리, 또는 list가 0일 때 nodata 처리
            if (dateType === 'ongoing' && selector.matchRankReducer.match_recordType === 'NewPerio') {
                $("#noRankingData").show();
                $("#RankingDataDiv").hide();
                $("#ranking_list").hide();
            } else if (newList.length > 0) {
                $("#noRankingData").hide();
                $("#RankingDataDiv").show();
                $("#ranking_list").show();
            } else {
                $("#noRankingData").show();
                $("#RankingDataDiv").hide();
                $("#ranking_list").hide();
            }

            // 5) 내 랭킹 찾기
            const myRankIndex = newList.findIndex(item => item.userNickname === sessionStorage.getItem('nickname'));
            setmyrankingnum(myRankIndex);

            // 6) 페이지/hasMore 갱신
            setHasMore(page < totalPageCount);
            setTotalPages(totalPageCount);
            currentPageRef.current = page;

            // 7) InfoRankData 갱신
            //   - cdn, recordType, totalCount 등도 data에 들어있다면 그대로 덮어씌워주고,
            //   - list는 기존 목록 + 새 목록(중복 제거)으로 합친다.
            if (reset) {
                SetInfoRankData({...data, list: newList});
            } else {
                SetInfoRankData((prev) => ({
                    ...data,
                    list: [
                        ...prev.list,
                        ...newList.filter(item => !prev.list.some(prevItem => prevItem.userNickname === item.userNickname))
                    ]
                }));
            }
        } catch (error) {
            console.error("대회 종합 랭킹 조회 오류:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const goAward = () => {
        //대회 시상 내역 조회
        axios({
            method: 'post',
            url: process.env.REACT_APP_SERVER + '/api',
            data: {page: 1, pageSize: 10},
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'language': sessionStorage.getItem('Language'),
                'pUrl': encodeURIComponent('대회 시상 내역 조회'),
                'guid': location.state.tournamentId
            }
        }).then(function (response) {
            // console.log('[대회 시상 내역 조회] ',response.data);
            SetInfoAwardData(response.data);

            if (response.data.error !== undefined && response.data.error.code > 0) {
                common.alertByError('대회 상세 목록', response.data.error.code);
                return false;
            } else {
                let myawardboollisttemp = [];

                for (let i = 0; i < response.data.awardItems.length; i++) {
                    const x = response.data.awardItems[i].awardIndex;
                    if (response.data.awardRankings[x].length === 0) {
                        myawardboollisttemp.push(false);
                    } else {
                        for (let j = 0; j < response.data.awardRankings[x].length; j++) {
                            if (response.data.awardRankings[x][j].userGuid === sessionStorage.getItem('guid')) {
                                myawardboollisttemp.push(true);
                                setisnomyward(true);
                                break;
                            } else if (j === response.data.awardRankings[x].length - 1) {
                                myawardboollisttemp.push(false);
                            }
                        }
                    }
                }
                // console.log(myawardboollisttemp);
                setmyawardboollist(myawardboollisttemp);
            }

        }).catch(error => {
            console.log(error)
        });
    }

    const findCourseName = (id) => {
        if (InfoData.courseRounds !== undefined) {
            for (let i = 0; i < InfoData.courseRounds.length; i++) {
                if (id === InfoData.courseRounds[i].courseId) {
                    return InfoData.courseRounds[i].courseName;
                }
            }
        }
        return '';
    };

    const changeMyAwardShow = () => {
        setIsMyAwardShow($("#award1").is(":checked"));
        goAward();
    };

    const SlideRankDetail = (recordType, player) => {
        $("html").addClass("overflowHidden");
        setSelectPlayerNum(player);
        if (recordType === 'BirdieCount' || recordType === 'EagleCount') {
            $("#BirdieEaglePop").addClass("on");
        } else if (recordType === 'HoleInOne') {
            $("#HoleInOnePop").addClass("on");
        } else if (recordType === 'CourseRoundPlayCount') {
            $("#CourseRoundPlayCountPop").addClass("on");
        } else {
            $("#" + recordType + "Pop").addClass("on");
        }
    };

    const slideRecordType = () => {
        $("html").addClass("overflowHidden");
        $("#pop_recordType").addClass("on");
    };

    const slideCourseId = () => {
        $("html").addClass("overflowHidden");
        $("#pop_courseId").addClass("on");
    };

    const slideAwardId = () => {
        $("html").addClass("overflowHidden");
        $("#pop_awardId").addClass("on");
    };

    const slideExit = () => {
        $("html").removeClass("overflowHidden");
        $(".layerView").removeClass("on");
    };

    const awardRankingsFunc = (i) => {
        let html = [];
        const x = InfoAwardData.awardItems[i].awardIndex;
        for (let j = 0; j < InfoAwardData.awardRankings[x].length; j++) {
            html.push(
                <li key={j}>
                    {InfoAwardData.awardItems[i].awardItems === 'HoleInOne' && (
                        <strong className={"rank" + InfoAwardData.awardRankings[x][j].ranking}>
                            {InfoAwardData.awardRankings[x][j].ranking}
                        </strong>
                    )}
                    <div className="nickname">
                        <p
                            style={{
                                backgroundImage: "url(" + InfoRankData.cdn + InfoAwardData.awardRankings[x][j].userProfileImage + ")"
                            }}
                        >
                            photo
                        </p>
                        <em>{InfoAwardData.awardRankings[x][j].nickname}</em>
                    </div>
                    <span>
            {InfoAwardData.awardItems[i].awardItems === 'Longest' ||
            InfoAwardData.awardItems[i].awardItems === 'Nearpin'
                ? common.Length(InfoAwardData.awardRankings[x][j].finalRecord, '0')
                : InfoAwardData.awardItems[i].awardItems === 'HoleInOne'
                    ? InfoAwardData.awardRankings[x][j].record
                    : InfoAwardData.awardRankings[x][j].finalRecord}
                        <sub>
              {InfoAwardData.awardItems[i].awardItems === 'Longest' && common.Length('', 'm')}
                            {InfoAwardData.awardItems[i].awardItems === 'Nearpin' && common.Length('', 'm')}
                            {InfoAwardData.awardItems[i].awardItems === 'HoleInOne' && lan.L('hole')}
                            {InfoAwardData.awardItems[i].awardType === 'BirdieCount' && lan.L('개')}
                            {InfoAwardData.awardItems[i].awardType === 'EagleCount' && lan.L('개')}
            </sub>
          </span>
                </li>
            );
        }
        return html;
    };

    return (
        <>
            {/*------------------------------------ 랭킹 ------------------------------------*/}
            {!(InfoRankData.list === undefined || selectPlayerNum === -1 || InfoRankData.list.length === 0) &&
                <RankingBottomSheet
                    id={`${InfoRankData.recordType !== 'BirdieCount' && InfoRankData.recordType !== 'EagleCount' ? InfoRankData.recordType : 'BirdieEagle'}Pop`}
                    InfoData={InfoData}
                    InfoRankData={InfoRankData}
                    selectPlayerNum={selectPlayerNum}
                    slideExit={slideExit}
                    selector={selector}
                />
            }
            {/*------------------------------------// 랭킹 ------------------------------------*/}
            <BottomMatchRank
                defaultvalue="0"
                tournamentId={location.state.tournamentId}
                slideExit={slideExit}
                InfoData={InfoData}
            />
            <BottomMatchAward
                defaultvalue="0"
                tournamentId={location.state.tournamentId}
                slideExit={slideExit}
                InfoAwardData={InfoAwardData}
            />
            <div className="contents">
                <div className="main_info"
                     style={{backgroundImage: `${require('../images/sub/match_bg_default.jpg')}`}}>{/* 첨부파일 없을 시 디폴트 BG */}
                    <span>{InfoData.startTime === undefined ? '' : common.UnixTime(InfoData.startTime, '년.월.일') + "~" + common.UnixTime(InfoData.endTime, '년.월.일')}</span>
                    <em>{InfoData.title === undefined ? '' : InfoData.title}</em>
                    <span>
                <small>{InfoData.hostShopName === undefined ? '' : InfoData.hostShopName}</small>
                <small>{InfoData.participantCount === undefined ? '' : InfoData.participantCount}</small>
                </span>
                    <span><i className={dateType}>{dateText}</i></span>
                </div>
                <MatchTab topics={[{tabName: '대회정보'}, {tabName: '랭킹'}, {tabName: '시상내역'}]} defaultTopics={0}></MatchTab>

                {/*------------------------------------ 대회정보 ------------------------------------*/}
                <aside style={activeTab === 0 ? {display: 'block'} : {display: 'none'}}>
                    <ul className="section">
                        <CourseRoundsInformation
                            InfoData={InfoData}
                        />
                        <RoundSettingInformation
                            InfoData={InfoData}
                            groupedByGradeType={groupedByGradeType}
                        />
                    </ul>
                </aside>
                {/*------------------------------------//대회정보 ------------------------------------*/}

                {/*------------------------------------ 랭킹 ------------------------------------*/}
                <aside style={activeTab === 1 ? {display: 'block'} : {display: 'none'}}>
                    <div className="match_filter">
                    <span>
                        <label className="filter_index" onClick={slideRecordType}
                               id="recordType">{lan.L(selector.matchRankReducer.match_recordType)}</label>
                        {/*신페리오로 고정되는거 수정*/}
                    </span>
                        <span>
                        <label className="filter_index" onClick={slideCourseId}>
                            {InfoData.courseRounds === undefined ? ''
                                : InfoData.isNewRankingSystem ? selector.matchRankReducer.match_courseNum === 'all' ? `${lan.L('합산')}`
                                        : `${lan.L('코스')}${selector.matchRankReducer.match_courseNum + 1}. ${InfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseName}`
                                    : InfoData.courseRounds[selector.matchRankReducer.match_courseNum].courseName}
                        </label>
                    </span>
                    </div>
                    {/* No Data */}
                    <div className="nodata" style={{display: 'none'}} id="noRankingData">
                        <p><img src={require("../images/character/nodata_5.png")} alt='No Data'/></p>
                        <strong>{lan.L('대회 랭킹 정보가 없습니다.')}</strong>
                        <span>{lan.L('대회 정보를 확인해 보세요.')}</span>
                    </div>

                    {!(myrankingnum === -1 || InfoRankData.list.length === 0) &&
                        <>
                            {(selector.matchRankReducer.match_recordType !== 'NewPerio' || dateType === 'ending') && (
                                <div
                                    className={`myranking ${InfoRankData.recordType?.toLowerCase() || ''} ${InfoData.isNewRankingSystem ? 'new' : ''}`}
                                    id="RankingDataDiv"
                                    onClick={(e) => SlideRankDetail(selector.matchRankReducer.match_recordType, myrankingnum)}> {/* 팝업 종합 코스 랭킹 */}
                                    {InfoRankData.recordType !== 'HoleInOne' &&
                                        <strong>{InfoRankData.list[myrankingnum].totalRanking}</strong>}
                                    <div className="nickname" data-new={InfoData.isNewRankingSystem}>
                                        <p style={{backgroundImage: "url(" + InfoRankData.cdn + InfoRankData.list[myrankingnum].userProfileImage + ")"}}>photo</p>
                                        {InfoData.isNewRankingSystem &&
                                            <>
                                                {(InfoRankData.recordType === 'Stroke' || InfoRankData.recordType === 'NewPerio') ?
                                                    <i>
                                                        <img
                                                            src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[myrankingnum].tournamentGradeType}_${InfoRankData.list[myrankingnum].tournamentStepType}.png`)}`}
                                                            alt=''/>
                                                    </i>
                                                    :
                                                    <i>
                                                        <img
                                                            src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[myrankingnum].userGradeType}_${InfoRankData.list[myrankingnum].userStepType}.png`)}`}
                                                            alt=''/>
                                                    </i>}
                                            </>
                                        }
                                        <em>{InfoRankData.list[myrankingnum].userNickname}</em>
                                    </div>
                                    {InfoRankData.recordType === 'Stroke' ?
                                        InfoData.isNewRankingSystem ?
                                            <>
                                                <span>{InfoRankData.list[myrankingnum].finalScore - (InfoRankData.list[myrankingnum].genderHandicap + InfoRankData.list[myrankingnum].handicap)}</span>
                                                <span>{InfoRankData.list[myrankingnum].genderHandicap + InfoRankData.list[myrankingnum].handicap}</span>
                                                <span>{InfoRankData.list[myrankingnum].finalScore}</span>
                                            </>
                                            :
                                            <span>{InfoRankData.list[myrankingnum].courseTotalScore}<sub>{lan.L('타')}</sub></span>
                                        : ''}
                                    {InfoRankData.recordType === 'NewPerio' ?
                                        InfoData.isNewRankingSystem ?
                                            selector.matchRankReducer.match_courseNum === 'all' ?
                                                <span>{InfoRankData.list[myrankingnum].finalScore}</span> :
                                                <>
                                                    <span>{InfoRankData.list[myrankingnum].stroke}</span>
                                                    <span>-</span>
                                                    <span>{InfoRankData.list[myrankingnum].finalScore}</span>
                                                </>
                                            :
                                            <span>{InfoRankData.list[myrankingnum].newPerioFinalRecord}<sub>{lan.L('타')}</sub></span>
                                        : ''}
                                    {InfoRankData.recordType === 'BirdieCount' ?
                                        <span>{InfoRankData.list[myrankingnum].birdieCount}<sub>{lan.L('회')}</sub></span> : ''}
                                    {InfoRankData.recordType === 'EagleCount' ?
                                        <span>{InfoRankData.list[myrankingnum].eagleCount}<sub>{lan.L('회')}</sub></span> : ''}
                                    {InfoRankData.recordType === 'Longest' ?
                                        <span>{InfoData.courseRounds[0].longestHoleNumber + "H " + common.Length(InfoRankData.list[selectPlayerNum].record, '0m')}</span> : ''}
                                    {InfoRankData.recordType === 'Nearpin' ?
                                        <span>{InfoData.courseRounds[0].nearpinHoleNumber + "H " + common.Length(InfoRankData.list[selectPlayerNum].record, '0m')}</span> : ''}
                                    {InfoRankData.recordType === 'HoleInOne' ?
                                        InfoData.isNewRankingSystem ?
                                            <>
                                                <span className='course'>{myCourseData?.courseName}</span>
                                                <span
                                                    className='hole'>{InfoRankData.list[myrankingnum].holeNumber}</span>
                                                <span
                                                    className='date'>{common.UnixTime(InfoRankData.list[myrankingnum].recordTime, '년-월-일')}</span>
                                            </>
                                            :
                                            <span>{InfoRankData.list[myrankingnum].holeNumber}<sub>{lan.L('Hole')}</sub></span>
                                        : ''}
                                    {InfoRankData.recordType === 'CourseRoundPlayCount' ?
                                        <span>{InfoRankData.list[myrankingnum].totalCourseRoundPlayCount}<sub>{lan.L('회')}</sub></span> : ''}

                                </div>
                            )}
                        </>
                    }

                    <ul className={`ranking_list ${InfoRankData.recordType?.toLowerCase() || ''} ${InfoData.isNewRankingSystem ? 'new' : ''}`}
                        id="ranking_list">
                        {InfoData.isNewRankingSystem && (
                            <li className='ranking_list_head'
                                style={{top: myrankingnum === -1 || InfoRankData.list.length === 0 ? '49px' : '122px'}}>
                                {InfoRankData.recordType === 'Stroke' && (
                                    <>
                                        <strong className='rank'>{lan.L('순위')}</strong>
                                        <div className='nickname'>{lan.L('닉네임')}</div>
                                        <span>{lan.L('스코어')}</span>
                                        <span>{lan.L('핸디캡')}</span>
                                        <span>Total</span>
                                    </>
                                )}
                                {InfoRankData.recordType === 'NewPerio' && (
                                    <>
                                        <strong className='rank'>{lan.L('순위')}</strong>
                                        <div className='nickname'>{lan.L('닉네임')}</div>
                                        {selector.matchRankReducer.match_courseNum === 'all' ?
                                            <span>Total</span> :
                                            <>
                                                <span>{lan.L('스코어')}</span>
                                                <span>{lan.L('핸디캡')}</span>
                                                <span>Total</span>
                                            </>
                                        }
                                    </>
                                )}
                                {(InfoRankData.recordType === 'BirdieCount' || InfoRankData.recordType === 'EagleCount' || InfoRankData.recordType === 'CourseRoundPlayCount') && (
                                    <>
                                        <strong className='rank'>{lan.L('순위')}</strong>
                                        <div className='nickname'>{lan.L('닉네임')}</div>
                                        <span>{lan.L('횟수')}</span>
                                    </>
                                )}
                                {InfoRankData.recordType === 'Longest' && (
                                    <>
                                        <strong className='rank'>{lan.L('순위')}</strong>
                                        <div className='nickname'>{lan.L('닉네임')}</div>
                                        <span>{lan.L('비거리')}</span>
                                    </>
                                )}
                                {InfoRankData.recordType === 'Nearpin' && (
                                    <>
                                        <strong className='rank'>{lan.L('순위')}</strong>
                                        <div className='nickname'>{lan.L('닉네임')}</div>
                                        <span>{lan.L('남은거리')}</span>
                                    </>
                                )}
                                {InfoRankData.recordType === 'HoleInOne' && (
                                    <>
                                        <div className='nickname'>{lan.L('닉네임')}</div>
                                        <span className='course'>{lan.L('코스이름')}</span>
                                        <span className='hole'>Hole</span>
                                        <span className='date'>{lan.L('날짜')}</span>
                                    </>
                                )}
                            </li>
                        )}

                        <InfiniteScroll
                            dataLength={InfoRankData.list.length}
                            next={() => goRanking(currentPageRef.current + 1)}
                            hasMore={hasMore}
                            loader={<h4>Loading...</h4>}
                        >
                            {InfoRankData.list.length === 0 ? '' :
                                InfoRankData.list.map((url, i) => {
                                    const courseData = InfoData.courseRounds?.find((p) => p.courseId === InfoRankData.list[i].courseId);
                                    return (
                                        <li key={i}
                                            onClick={() => SlideRankDetail(selector.matchRankReducer.match_recordType, i)}>
                                            {InfoRankData.recordType !== 'HoleInOne' &&
                                                <strong
                                                    className={"rank" + InfoRankData.list[i].totalRanking}>{InfoRankData.list[i].totalRanking}</strong>
                                            }
                                            <div className="nickname">
                                                <p style={{backgroundImage: "url(" + InfoRankData.cdn + InfoRankData.list[i].userProfileImage + ")"}}>photo</p>
                                                {InfoData.isNewRankingSystem &&
                                                    <>
                                                        {(InfoRankData.recordType === 'Stroke' || InfoRankData.recordType === 'NewPerio') ?
                                                            <i>
                                                                <img
                                                                    src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[i].tournamentGradeType}_${InfoRankData.list[i].tournamentStepType}.png`)}`}
                                                                    alt=''/>
                                                            </i>
                                                            :
                                                            <i>
                                                                <img
                                                                    src={`${require(`/src/images/grade/ui_icon_grade_${InfoRankData.list[i].userGradeType}_${InfoRankData.list[i].userStepType}.png`)}`}
                                                                    alt=''/>
                                                            </i>}
                                                    </>
                                                }
                                                <em>{InfoRankData.list[i].userNickname}</em>
                                            </div>
                                            {InfoRankData.recordType === 'Stroke' ?
                                                InfoData.isNewRankingSystem ?
                                                    <>
                                                        <span>{InfoRankData.list[i].finalScore - (InfoRankData.list[i].genderHandicap + InfoRankData.list[i].handicap)}</span>
                                                        <span>{InfoRankData.list[i].genderHandicap + InfoRankData.list[i].handicap}</span>
                                                        <span>{InfoRankData.list[i].finalScore}</span>
                                                    </>
                                                    :
                                                    <span>{InfoRankData.list[i].courseTotalScore}<sub>{lan.L('타')}</sub></span>
                                                : ''}
                                            {InfoRankData.recordType === 'NewPerio' ?
                                                InfoData.isNewRankingSystem ?
                                                    selector.matchRankReducer.match_courseNum === 'all' ?
                                                        <span>{InfoRankData.list[i].finalScore}</span> :
                                                        <>
                                                            <span>{InfoRankData.list[i].stroke}</span>
                                                            <span>-</span>
                                                            <span>{InfoRankData.list[i].finalScore}</span>
                                                        </>
                                                    :
                                                    <span>{InfoRankData.list[i].newPerioFinalRecord}<sub>{lan.L('타')}</sub></span>
                                                : ''}
                                            {InfoRankData.recordType === 'BirdieCount' ?
                                                <span>{InfoRankData.list[i].birdieCount}<sub>{lan.L('회')}</sub></span> : ''}
                                            {InfoRankData.recordType === 'EagleCount' ?
                                                <span>{InfoRankData.list[i].eagleCount}<sub>{lan.L('회')}</sub></span> : ''}
                                            {InfoRankData.recordType === 'Longest' ?
                                                <span>{InfoData.courseRounds[0].longestHoleNumber + "H " + common.Length(InfoRankData.list[i].record, '0m')}</span> : ''}
                                            {InfoRankData.recordType === 'Nearpin' ?
                                                <span>{InfoData.courseRounds[0].nearpinHoleNumber + "H " + common.Length(InfoRankData.list[i].record, '0m')}</span> : ''}
                                            {InfoRankData.recordType === 'HoleInOne' ?
                                                InfoData.isNewRankingSystem ?
                                                    <>
                                                        <span className='course'>{courseData.courseName}</span>
                                                        <span className='hole'>{InfoRankData.list[i].holeNumber}</span>
                                                        <span
                                                            className='date'>{common.UnixTime(InfoRankData.list[i].recordTime, '년-월-일')}</span>
                                                    </>
                                                    :
                                                    <span>{InfoRankData.list[i].holeNumber}<sub>{lan.L('Hole')}</sub></span>
                                                : ''}
                                            {InfoRankData.recordType === 'CourseRoundPlayCount' ?
                                                <span>{InfoRankData.list[i].totalCourseRoundPlayCount}<sub>{lan.L('회')}</sub></span> : ''}
                                        </li>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    </ul>
                </aside>

                {/* 플로팅 버튼 */}
                {activeTab === 1 && show ? (
                    <button
                        style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px',
                            zIndex: 999,
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            backgroundColor: 'transparent',
                            color: '#737980',
                            cursor: 'pointer',
                            fontSize: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor"
                             className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                        </svg>
                    </button>
                ) : null}

                {/*------------------------------------//랭킹 ------------------------------------*/}
                {/*------------------------------------ 시상내역 ------------------------------------*/}
                <aside style={activeTab === 2 ? {display: 'block'} : {display: 'none'}}>
                    <div className="match_filter">
                    <span>
                        <a>
                        <input type="checkbox" id="award1" name="award" onChange={changeMyAwardShow}/>
                        <label htmlFor="award1">{lan.L('나의 시상내역')}</label>
                        </a>
                    </span>
                        <span>
                        <label className="filter_index"
                               onClick={slideAwardId}>{InfoAwardData.awardItems === undefined || InfoAwardData.awardItems.length == 0 || selector.matchRankReducer.match_awardNum == 'all' ? lan.L('전체보기') : InfoAwardData.awardItems[selector.matchRankReducer.match_awardNum].awardName}</label>
                    </span>
                    </div>
                    {/* No Data */}
                    {InfoAwardData.awardItems === undefined || InfoAwardData.awardItems.length === 0 || (isMyAwardShow && !isnomyward) &&
                        <div className="nodata">
                            <p><img src={require("../images/character/nodata_5.png")}/></p>
                            <strong>{lan.L('대회 시상 정보가 없어요.')}</strong>
                            <span>{lan.L('대회 정보를 확인해 보세요.')}</span>
                        </div>
                    }
                    {/*//No Data */}
                    <ul className="award">
                        {!(InfoAwardData.awardItems === undefined || InfoAwardData.awardItems.length === 0) &&
                            InfoAwardData.awardItems.map((url, i) => (
                                <>
                                    {isMyAwardShow && !myawardboollist[i] || (selector.matchRankReducer.match_awardNum !== 'all' && selector.matchRankReducer.match_awardNum !== i) ? '' :    //나의 시상내역 체크 및 해당 값 안에 내 아이디랑 일치하는게 없으면 숨김처리
                                        <li key={i}>
                                            <em>{InfoAwardData.awardItems[i].awardName}</em>
                                            <div className="substance">
                                                <dl>
                                                    <dd>{lan.L('시상타입')}</dd>
                                                    <dt>{InfoData.isNewRankingSystem ? lan.L(InfoAwardData.awardItems[i].awardType) : InfoAwardData.awardItems[i].awardType}</dt>
                                                </dl>
                                                {InfoData.isNewRankingSystem ? '' :
                                                    InfoAwardData.awardItems[i].awardItems === 'HoleInOne' || InfoAwardData.awardItems[i].awardItems === 'CourseRoundPlayCount' ? '' :
                                                        <>
                                                            <dl>
                                                                <dd>{lan.L('코스이름')}</dd>
                                                                <dt>{findCourseName(InfoAwardData.awardItems[i].courseId)}</dt>
                                                            </dl>
                                                        </>
                                                }
                                                <dl>
                                                    <dd>{lan.L('시상상품명')}</dd>
                                                    <dt>{InfoAwardData.awardItems[i].awardProduct}</dt>
                                                </dl>
                                            </div>
                                            <ul className="prize_rank">
                                                {awardRankingsFunc(i)}
                                            </ul>
                                        </li>
                                    }
                                </>
                            ))}
                    </ul>
                </aside>
                {/*------------------------------------//시상내역 ------------------------------------*/}
            </div>
        </>
    );
}
